import React, { useMemo } from 'react';

import {
  useGetAcEmptylegs,
  useHideEmptyleg,
  useRemoveEmptyleg,
} from '@/API/aircraftApi';
import { Aircraft, Emptyleg } from '@/API/models';
import EmptyLegRow from '@/components/Operator/EmptyLegModal/EmptyLegRow';
import Spinner from '@/components/Spinner';

type Props = {
  aircraft: Aircraft;
  onEdit: (el: Emptyleg) => void;
};
const EmptyLegsList: React.FC<Props> = ({ aircraft, onEdit }) => {
  const { data, isError, isLoading } = useGetAcEmptylegs(`${aircraft.id}`);

  const { mutateAsync: removeEmptyLeg } = useRemoveEmptyleg();
  const { mutateAsync: hideEmptyLeg } = useHideEmptyleg();

  const renderList = (data: Emptyleg[] | undefined) => {
    return data?.map((emptyleg, i) => (
      <EmptyLegRow
        onEdit={onEdit}
        onDelete={async () => {
          await removeEmptyLeg({
            emptyleg: emptyleg.emptyleg_id,
            aircraft_id: aircraft.id,
          });
        }}
        onHide={async () => {
          await hideEmptyLeg({
            emptyleg: emptyleg,
            aircraft_id: aircraft.id,
          });
        }}
        key={emptyleg._id ?? emptyleg.emptyleg_id}
        emptyleg={emptyleg}
      />
    ));
  };

  return (
    <div className='flex flex-wrap gap-8'>
      <h1 className='text-4xl leading-relaxed w-full mb-10'>
        All EmptyLegs For #{aircraft.tail_number}
        <p className='text-3xl leading-relaxed w-full'>
          <span className=' inline-block w-4 h-4 bg-indigo-200'></span> created
          on evolux
        </p>
      </h1>
      {isLoading ? (
        <div className='w-full flex justify-center items-center'>
          <Spinner />
        </div>
      ) : data?.length ? (
        renderList(data)
      ) : (
        <p className='w-full leading-relaxed text-xl'>
          No empty legs were found
        </p>
      )}
      {/* <h1>test data</h1>
      {Array(10)
        .fill(0)
        .map((_, i) => (
          <EmptyLegRow
            key={i}
            onEdit={onEdit}
            emptyleg={{
              evolux: Math.random() > 0.5,
              departureAirport: { airport_code: 'KMIA' },
              arrivalAirport: { airport_code: 'KMIA' },
              date1: new Date().toString(),
              date2: new Date().toString(),
              price: Math.floor(Math.random() * 10000),
              flightTimeDec: +(Math.random() * 10 + 1).toPrecision(2),
            }}
          />
        ))} */}
    </div>
  );
};

export default EmptyLegsList;
