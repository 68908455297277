import React from 'react';
const Off = () => {
  return (
    <svg
      width='60'
      height='40'
      viewBox='0 0 60 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='6' y='6' width='48' height='28' rx='14' fill='#D7D9DA' />
      <g filter='url(#filter0_d_3328_2234)'>
        <circle cx='20' cy='20' r='10' fill='white' />
      </g>
      <defs>
        <filter
          id='filter0_d_3328_2234'
          x='5'
          y='6'
          width='32'
          height='32'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3328_2234'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3328_2234'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
export default Off;
