import React from 'react';

import BookingCard from '@/components/Bookings/BookingCard';

type Props = { bookings: any[] };

const BookingList: React.FC<Props> = ({ bookings = [1] }) => {
  return (
    <ul>
      {bookings.map((b) => (
        <BookingCard />
      ))}
    </ul>
  );
};
export default BookingList;
