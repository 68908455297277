import { useQuery } from 'react-query';

import { debounce } from '@/Utils/helpers';

import { QueryMethod } from '../types/API';
import api from './config';

export interface Airport {
  id: string;
  label: string;
  value: string;
  name: string;
  lat: number;
  lng: number;
  region: number;
  title: string;
  location: string;
  zip: null;
  street: string;
  vertiport_type: null;
  airport_code: string;
}

// regular axios call
export const searchAirport = debounce(async (search: string) => {
  const { data } = await api.get(
    `/flyeasy/airports/autocomplete?term=${search}`,
  );
  return data.airports as Airport[];
});

// react-query version
const useSearchAirport: QueryMethod<Airport[]> = (search: string, options) => {
  return useQuery(
    ['search_airport', search],
    async () => {
      const { data } = await api.get<{ airports: Airport[] }>(
        `/flyeasy/airports/autocomplete?term=${search}`,
      );
      return data.airports;
    },
    options,
  );
};

export default useSearchAirport;
