import React, { useState } from 'react';

type Props = {
  title: string;
  openByDefault?: boolean;
  className?: { container: string; title: string };
};

const Accordion: React.FC<Props> = ({
  title,
  openByDefault = false,
  className,
  children,
}) => {
  const [open, setOpen] = useState(openByDefault);

  return (
    <div
      className={`bg-white p-4 box-border w-full ${className?.container ?? ''}`}
      style={{
        border: '1px solid #BABBBE',
        borderRadius: '4px',
      }}
    >
      <h1
        onClick={() => setOpen((current) => !current)}
        className={`w-full flex justify-between items-center 
				cursor-pointer font-serif p-4 text-3xl tracking-wide font-normal  box-border border-0 border-b border-solid border-gray-200 ${className?.title}`}
      >
        <span className='text-18px leading-24px'>{title}</span>
        {open ? (
          <img src='/icons/minimize.svg' alt='min' />
        ) : (
          <img src='/icons/maximize.svg' alt='max' />
        )}
      </h1>
      {open && children}
    </div>
  );
};
export default Accordion;
