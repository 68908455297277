import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { FiInfo } from 'react-icons/fi';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import Popover from '@/components/Popover';

export type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  name: string;
  label?: string;
  text?: string;
  preText?: string;
  noLabel?: boolean;
  info?: string;
  style?: React.CSSProperties;
  labelClassName?: string;
  isRequired?: boolean;
};

const FormElement: React.FC<InputProps> = (props) => {
  const { className, noLabel, type, info, isRequired, labelClassName } = props;
  const name = props.name.replace('_', ' ');
  return (
    <dl
      className={className}
      style={type === 'checkbox' ? { display: 'flex' } : {}}
    >
      {!noLabel && (
        <dt className='justify-start text-16px'>
          <label className={labelClassName} htmlFor={props.name}>
            {props.label || name}
            {isRequired && (
              <span className='text-red-500 font-normal text-16px'> *</span>
            )}
          </label>
          {info && (
            <Popover
              largerText
              Btn={() => (
                <IoIosInformationCircleOutline
                  size={13}
                  className='mx-2 text-gray-600'
                />
              )}
              content={info}
            />
          )}
        </dt>
      )}
      <dd style={props.style}>{props.children}</dd>
    </dl>
  );
};
export default FormElement;
