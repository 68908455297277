import React, { useMemo, useState } from 'react';

import { Aircraft, Emptyleg } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import Modal from '@/components/Modal';
import AddEmptyLegForm from '@/components/Operator/AddEmptyLegForm';
import AircraftCard from '@/components/Operator/AircraftCard';
import EditEmptyLegForm from '@/components/Operator/EditEmptyLegForm';
import EmptyLegsList from '@/components/Operator/EmptyLegsList';
import useMediaQuery from '@/Utils/useMediaQuery';

type Props = {
  aircrafts: Aircraft[];
};

const OperatorFleetList: React.FC<Props> = ({ aircrafts }) => {
  // console.log({ aircrafts });
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [selected, setSelected] = useState<Aircraft | null>(null);
  const [selectedEmptyLeg, setSelectedEmptyLeg] = useState<Emptyleg>();
  const [modalType, setModalType] = useState<'view' | 'edit' | 'add'>();
  const [message, setMessage] = useState<string>();

  // used when admin acting as operator
  const operatorId = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('operator_id') || undefined;
  }, []);

  const addEmptyLeg = (ac: Aircraft) => {
    setSelected(ac);
    setModalType('add');
  };
  const viewEmptyLeg = (ac: Aircraft) => {
    setSelected(ac);
    setModalType('view');
  };
  const editEmptyLeg = (el: Emptyleg) => {
    setSelectedEmptyLeg(el);
    setModalType('edit');
  };
  const closeModal = (msg?: string) => {
    msg && setMessage(msg);
    setSelected(null);
  };

  return (
    <ReactQueryProvider>
      <Modal
        style={{
          content: {
            maxHeight: '90vh',
            overflow: modalType === 'view' ? 'auto' : 'visible',
            maxWidth: modalType === 'add' && !isMobile ? '50%' : undefined,
          },
        }}
        isOpen={!!selected}
        onRequestClose={() => closeModal()}
        shouldCloseOnOverlayClick={!(modalType === 'add')}
      >
        {selected &&
          (modalType === 'add' ? (
            <AddEmptyLegForm
              aircraft={selected}
              afterSubmit={() => closeModal('EmptyLeg added successfully')}
            />
          ) : modalType === 'edit' ? (
            selectedEmptyLeg && (
              <EditEmptyLegForm
                aircraft={selected}
                emptyleg={selectedEmptyLeg}
                afterSubmit={() => closeModal('EmptyLeg modifed successfully')}
              />
            )
          ) : (
            <EmptyLegsList aircraft={selected} onEdit={editEmptyLeg} />
          ))}
      </Modal>
      {message && (
        <div className='rounded p-10 my-5 bg-green-100 text-gray-600'>
          {message}
        </div>
      )}
      <div className='grid gap-4 grid-cols-resp-35 clear-both'>
        {aircrafts.map((aircraft, i) => (
          <AircraftCard
            operatorId={operatorId}
            aircraft={aircraft}
            add={addEmptyLeg}
            view={viewEmptyLeg}
            key={i}
          />
        ))}
      </div>
    </ReactQueryProvider>
  );
};
export default OperatorFleetList;
