/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
// ${path}
export const yupCustom = {
  mixed: {
    required: 'This field is required',
    notType: 'Not a valid value',
  },
  number: {
    min: 'Value must be more than ${min}',
    max: 'Value must be less than ${max}',
    positive: 'Value must be positive',
  },
};
yup.setLocale(yupCustom);
export { yup };
