import React from 'react';

export type CardDataType = { number: string; date: string };
export type PreviousBillingFormProps = {
  action: string;
  cards: CardDataType[];
  flight_id: string;
  id: string; // booking id
};

const PreviousBillingForm: React.FC<PreviousBillingFormProps> = ({
  action,
  cards,
  flight_id,
  id,
}) => {
  return (
    <form action={action} method='POST'>
      <input type='hidden' name='id' value={id} />
      <input type='hidden' name='flight_id' value={flight_id} />
      {cards.map(({ number, date }) => {
        const name =
          cards.length > 1
            ? `use_previous_billing.${number}`
            : 'use_previous_billing';
        return (
          <span key={id} className='flex mt-2'>
            <input
              type='radio'
              name='card'
              id={name}
              className='mr-5'
              defaultChecked={!!cards.length}
            />
            <label
              htmlFor={name}
              className={`w-96 flex justify-between m-2 p-4 rounded-xl cursor-pointer border-4 border-solid`}
            >
              <p className='font-sans text-2xl font-bold '>
                {number.padStart(19, '**** ')}
              </p>
              <p className='font-sans text-2xl font-bold '>{date}</p>
            </label>
          </span>
        );
      })}
      <button
        id='submit'
        className='cursor-pointer my-5 px-10 py-5 rounded-md border-none text-xl font-black bg-red-500 hover:bg-red-600 text-white'
      >
        <span id='button-text'>Confirm</span>
      </button>
    </form>
  );
};
export default PreviousBillingForm;
