import { createPopper } from '@popperjs/core';
import React from 'react';

type Props = {
  Btn: React.FC;
  content: string;
  largerText?: boolean;
};

const Popover: React.FC<Props> = ({ content, Btn, largerText = false }) => {
  const [popoverShow, setPopoverShow] = React.useState(false);
  const btnRef = React.useRef<HTMLDivElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const openPopover = () => {
    createPopper(btnRef.current!, popoverRef.current!, {
      placement: 'top',
    });
    setPopoverShow(true);
  };
  const closePopover = () => {
    setPopoverShow(false);
  };
  return (
    <div className='flex flex-wrap'>
      <div className='w-full text-center'>
        <div
          className='border-none p-0 bg-transparent popover-icon'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            popoverShow ? closePopover() : openPopover();
          }}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
          ref={btnRef}
        >
          <Btn />
        </div>
        <div
          className={
            (popoverShow ? 'block ' : 'hidden ') +
            'bg-white text-black font-serif ' +
            'border-1 border-solid border-inputBorder z-101 font-normal leading-normal text-lg text-left no-underline rounded-lg p-2'
          }
          ref={popoverRef}
        >
          <pre
            className={`p-3 color-customBlack text-14px font-serif font-normal max-w-sm whitespace-pre-wrap ${
              largerText ? 'text-xl' : ''
            }`}
          >
            {content}
          </pre>
        </div>
      </div>
    </div>
  );
};
export default Popover;
// export default function PopoverRender() {
//   return (
//     <>
//       return <Popover color="pink" />
//     </>
//   );
// }
