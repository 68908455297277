import { useFormikContext } from 'formik';
import React from 'react';

type Props = {};

const DebugForm: React.FC<Props> = ({}) => {
  const { errors, values, touched } = useFormikContext();
  return <pre>{JSON.stringify({ errors, values, touched }, null, 2)}</pre>;
};
export default DebugForm;
