import { throttle as _throttle } from 'lodash';
import { useEffect } from 'react';

const getPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
});

/* Feature detection */
let passiveIfSupported = { passive: false };
try {
  window.addEventListener(
    'test' as any,
    null as any,
    Object.defineProperty({}, 'passive', {
      get: function () {
        passiveIfSupported = { passive: true };
      },
    }),
  );
} catch (err) {}

const useOnScroll = (onScroll: (pos: { x: number; y: number }) => void) => {
  const opts = {
    throttle: 100,
  };
  useEffect(() => {
    const handleScroll = _throttle(() => {
      onScroll(getPosition());
    }, opts.throttle);

    window.addEventListener('scroll', handleScroll, passiveIfSupported);

    return () => {
      handleScroll.cancel();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};

export default useOnScroll;
