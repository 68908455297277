import { useField } from 'formik';
import React from 'react';

import { ErrorMessage } from '@/components/Forms';
import FormElement, { InputProps } from '@/components/Forms/FormElement';
import Off from '@/components/SVG/ToggleOff';
import On from '@/components/SVG/ToggleOn';

type Props = InputProps;

const Toggle: React.FC<Props> = (props) => {
  const [{ value }, _, { setValue }] = useField(props.name);
  const click = () => setValue(!value);
  const { disabled } = props;
  return (
    <FormElement {...props}>
      <button
        type='button'
        className=' bg-transparent border-0 flex text-left items-center gap-6 text-16px w-full whitespace-normal'
        onClick={click}
        disabled={disabled}
      >
        <span className='w-max'>{value ? <On /> : <Off />}</span>
        {props.children ?? props.placeholder ?? props.label}
      </button>
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
export default Toggle;
