import React from 'react';

import { Emptyleg } from '@/API/models';
import Card from '@/components/Card';

type Props = {
  emptyLeg: Emptyleg;
};

const CardAside: React.FC<Props> = ({ emptyLeg }) => {
  return (
    <div className={`grid gap-10 p-4`}>
      <Card selected={false} {...emptyLeg} displayOnly />
    </div>
  );
};
export default CardAside;
