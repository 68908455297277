import React from 'react';

import ReactQueryProvider from '@/API/ReactQueryProvider';
import PhoneForm from '@/components/PhoneVerification/PhoneForm';

export type Props = {
  phone?: string;
};

const PhoneNumberVerification: React.FC<Props> = (props) => {
  return (
    <ReactQueryProvider>
      <PhoneForm {...props} />
    </ReactQueryProvider>
  );
};
export default PhoneNumberVerification;
