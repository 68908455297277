import React from 'react';

const ContainerForInputs = ({ children }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 justify-center w-full gap-x-14 pt-4'>
      {children}
    </div>
  );
};

export default ContainerForInputs;
