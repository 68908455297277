import { useMutation, useQuery, useQueryClient } from 'react-query';

import api from '@/API/config';
import { QueryMethod } from '@/types/API';

export type Notification = {
  id: string;
  subject: string;
  sentAt: string;
  content: string;
  onClickUrl: string;
  read: boolean;
};
// react-query version
const useGetNotifications: QueryMethod<Notification[]> = (
  notifications,
  options,
) => {
  return useQuery(
    'notifications',
    async () => {
      return notifications;
    },
    options,
  );
};
const useMarkAsRead = (options?) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const response = api.put(`notifications/${id}/mark_as_read`);
      return response;
    },
    {
      onSuccess: (data, id) => {
        queryClient.setQueryData('notifications', (old: Notification[]) =>
          old.map((notif) => {
            if (notif.id == id) {
              notif = { ...notif, read: true };
            }
            return notif;
          }),
        );
      },
      ...options,
    },
  );
};

export { useGetNotifications, useMarkAsRead };
