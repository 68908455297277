import React from 'react';

import SearchMap from '@/components/SearchMap';

interface Route {
  aircraftType: string;
  departureAirport: Coord;
  arrivalAirport: Coord;
}
interface Coord {
  lat: number;
  lng: number;
}

type Props = {
  route: Route;
  google_maps_api_key: string;
};

const MapView: React.FC<Props> = ({ google_maps_api_key, route }) => {
  return (
    <div style={{ height: '40rem' }} className='w-full'>
      <SearchMap apiKey={google_maps_api_key} simple routes={[route as any]} />
    </div>
  );
};

export default MapView;
