import React from 'react';

const InvalidCodeError = ({ remainingAttempts, verificationError }) => {
  return (
    <div className='text-customRed'>
      {verificationError && (
        <p className='pt-2 text-customRed text-14px'>{verificationError}</p>
      )}
      <p className='pt-4 m-0 text-customRed text-14px'>
        You have attempts left: {remainingAttempts}
      </p>
      <p className='text-customRed text-14px'>
        If you exceed the limit, access will be blocked for 2 hours.
      </p>
    </div>
  );
};
export default InvalidCodeError;
