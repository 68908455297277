import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';

import { Airport } from '@/API/airportApi';
import { Emptyleg } from '@/API/models';
import useSearchEmptyLegs from '@/API/searchEmptyLegs';
import { AMENITIES } from '@/Utils/constants';
import { addDays, formatDate, strDate } from '@/Utils/helpers';

export const searchFormInitial = {
  from: {} as Airport | null,
  to: {} as Airport | null,
  type: [] as string[],
  amenities: [] as string[],
  safetyRating: [] as string[],
  dateRange: {
    from: undefined as Date | undefined,
    to: undefined as Date | undefined,
  },
  price: false,
  evolux_only: false,
  radius: 50,
};
type FormValues = typeof searchFormInitial;

const setUrlPath = ({ from, to, dateRange, ...other }: FormValues) => {
  window.history.pushState(
    null,
    '',
    window.location.pathname +
      '?' +
      $.param({
        departure_code: from?.airport_code,
        arrival_code: to?.airport_code,
        start_date: formatDate(dateRange.from),
        end_date: formatDate(dateRange.to),
        ...other,
      }),
  );
};

const useGetFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const start_date = searchParams.get('start_date');
  const end_date = searchParams.get('end_date');

  const depCode = searchParams.get('departure_code');
  const arrCode = searchParams.get('arrival_code');
  const valFromParams: FormValues = {
    amenities: searchParams.getAll('amenities[]'),
    dateRange: {
      from: start_date !== null ? strDate(start_date) : new Date(),
      to: end_date !== null ? strDate(end_date) : addDays(30),
    },
    from: depCode ? ({ airport_code: depCode } as Airport) : ({} as Airport),
    to: arrCode ? ({ airport_code: arrCode } as Airport) : ({} as Airport),
    price: searchParams.get('price') == 'true',
    evolux_only: searchParams.get('evolux_only') == 'true',
    safetyRating: searchParams.getAll('safetyRating[]'),
    type: searchParams.getAll('type[]'),
    radius: parseInt(
      searchParams.get('radius') ?? `${searchFormInitial.radius}`,
    ),
  };
  return valFromParams;
};

const filterData = (
  emptylegs: Emptyleg[],
  type: string[],
  amenities: string[],
  price: boolean,
  evolux_only: boolean,
) => {
  const filteredEmptylegs = emptylegs
    .filter((emptyLeg: Emptyleg) => {
      if (!emptyLeg) {
        return false;
      }
      let ok = true;
      if (type.length) {
        // ok &&= emptyLeg.ac.typeJson.value === type;
        ok &&= type.includes(emptyLeg.ac.typeJson.value);
      }
      if (amenities?.length > 0) {
        ok &&= amenities.every((feature) =>
          emptyLeg.ac.featureList.includes(feature),
        );
      }

      if (price) {
        ok &&= emptyLeg.price > 1;
      }
      if (evolux_only) {
        ok &&= !!emptyLeg.evolux;
      }
      return ok;
    })
    .sort((a, b) => {
      const dateA = new Date(a.date1.substring(0, 10));
      const dateB = new Date(b.date1.substring(0, 10));
      return dateA < dateB ? -1 : 1;
    });
  console.log(
    emptylegs.length + ' filtering data ...' + filteredEmptylegs.length,
  );
  return filteredEmptylegs;
};

const validate = (values: FormValues) => {
  if (!values.from?.airport_code && !values.to?.airport_code) {
    return {
      from: { airport_code: 'This field is required' },
      to: { airport_code: 'This field is required' },
    };
  }
  return {};
};

const useSearchEmptyLegForm = () => {
  // state / data
  const [searchParams, setSearchParams] = useState(useGetFromUrl);
  const {
    amenities,
    dateRange,
    from,
    price,
    radius,
    safetyRating,
    to,
    type,
    evolux_only,
  } = searchParams;
  const [filtered, setFiltered] = useState<Emptyleg[]>();
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [amenitiesList, setAmenitiesList] = useState(new Set<string>());
  const [isError, setIsError] = useState(false);
  const [enabled, setEnabled] = useState(() => {
    // enabled by default if params are present (from the url)
    return Object.values(validate(searchParams)).length === 0;
  });

  // search empty legs onChange
  const apiResponse = useSearchEmptyLegs(
    {
      departure_code: from?.airport_code ?? '',
      arrival_code: to?.airport_code ?? '',
      start_date: formatDate(dateRange.from),
      end_date: formatDate(dateRange.to),
      radius,
    },
    {
      enabled,
    },
  );

  // filtering the data
  useEffect(() => {
    setIsError(false);
    // update path
    setUrlPath({
      from,
      to,
      dateRange,
      type,
      amenities,
      safetyRating,
      price,
      evolux_only,
      radius,
    });

    if (!apiResponse.data) {
      return;
    }
    if (!apiResponse.data?.success && apiResponse.data.redirect_to) {
      return window.location.assign(apiResponse.data.redirect_to);
    }

    if (!apiResponse.data.success) {
      setIsError(true);
      return setFiltered([]);
    }
    setIsApplyingFilter(true);
    setTimeout(() => {
      const filteredEmptylegs = filterData(
        apiResponse.data.emptylegs,
        type,
        amenities,
        price,
        evolux_only,
      );
      setFiltered(filteredEmptylegs);

      // generating amenities list
      setAmenitiesList(() => {
        const list = new Set<string>(AMENITIES);
        filteredEmptylegs.forEach((el) => {
          el.ac.featureList.forEach((feature) => {
            list.add(feature);
          });
        });
        return list;
      });
      setIsApplyingFilter(false);
    }, 10);
  }, [type, amenities, price, evolux_only, apiResponse.data]);

  const onSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    if (apiResponse.isLoading) {
      return;
    }
    setSearchParams(values);
    setEnabled(true);
    setSubmitting(false);
  };
  return {
    ...apiResponse,
    isLoading: isApplyingFilter || apiResponse.isLoading,
    isError: isError || apiResponse.isError,
    data: { emptylegs: filtered, amenitiesList: Array.from(amenitiesList) },
    onSubmit,
    initialValues: searchParams,
    validate,
    setSearchParams,
    enabled,
  };
};

export const useUpdateSearchEmptyLeg = (
  setParams: React.Dispatch<React.SetStateAction<FormValues>>,
  values: FormValues,
  enabled: boolean,
) => {
  useEffect(() => {
    if (enabled && Object.values(validate(values)).length === 0) {
      setParams(values);
    }
  }, [values, enabled]);
};
export default useSearchEmptyLegForm;
