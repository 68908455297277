import React from 'react';

import CardSkeleton from '@/components/Skeleton/CardSkeleton';

type Props = {};

const mock = Array(6).fill(1);
const Skeleton: React.FC<Props> = ({}) => {
  return (
    <div className='p-8 results-grid grid gap-10 my-5'>
      {mock.map((el) => (
        <CardSkeleton />
      ))}
    </div>
  );
};
export default Skeleton;
