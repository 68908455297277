import React from 'react';

import Toggle from '@/components/Forms/Toggle';

const RegisterToggles = ({ isEditForm, role }) => {
  return (
    <div className='pt-8'>
      <div className='flex'>
        <Toggle
          name='agree_on_terms'
          className='w-auto'
          noLabel
          disabled={isEditForm}
        >
          <span className='flex items-baseline gap-2 flex-wrap'>
            I agree to EvoLux’s
            <a
              href={role === 'operator' ? '/operator/terms' : '/terms'}
              target={'_blank'}
              className='text-16px leading-none'
            >
              Terms of service
            </a>
            <span className='text-16px text-customDarkGrey leading-24px leading-none'>
              and
            </span>
            <a
              href='/privacy'
              target={'_blank'}
              className='text-16px leading-none'
            >
              Privacy Policy
            </a>
          </span>
        </Toggle>
      </div>

      {(role === 'user' || !isEditForm) && (
        <Toggle name='agree_on_sms_receive' className='w-auto' noLabel>
          <span className='text-16px text-customDarkGrey leading-24px text-left'>
            By providing my phone number, I agree to receive text messages from
            the EvoLux. Message frequency varies by your booking. Message and
            data rates may apply. You can unsubscribe in your profile at any
            time. Email info@evo-lux.com for help.
          </span>
        </Toggle>
      )}
    </div>
  );
};

export default RegisterToggles;
