import React, { useMemo } from 'react';

import ReactQueryProvider from '@/API/ReactQueryProvider';
import BlockCodeSend from '@/components/Forms/BlockCodeSend';
import CodeForm from '@/components/PhoneVerification/CodeForm';

export type Props = {
  sms_sent_at?: string;
  sms_attempts?: number;
  blocked_until?: string;
  phone?: string;
  verification_error: string | null;
};

const PhoneCodeVerification: React.FC<Props> = ({
  blocked_until,
  ...props
}) => {
  const isBlocked = useMemo(() => {
    if (!blocked_until) return false;

    const blockedUntil = new Date(blocked_until);
    return new Date() < blockedUntil;
  }, [blocked_until]);

  return (
    <ReactQueryProvider>
      <section className='bg-white flex w-full min-h-50vh justify-center'>
        <div className='py-16 max-w-400 flex flex-col'>
          {isBlocked ? <BlockCodeSend /> : <CodeForm {...props} />}
        </div>
      </section>
    </ReactQueryProvider>
  );
};
export default PhoneCodeVerification;
