import { useMemo, useState } from 'react';

import { yup } from '@/Utils/validationConfig';

export const usePhoneNumberForm = (phone: string) => {
  const [errors, setErrors] = useState<string[]>();

  const validationSchema = useMemo(
    () =>
      yup.object({
        phone: yup.string().min(6).required(),
      }),
    [],
  );

  const initialValues = useMemo(() => {
    return {
      phone,
    };
  }, [phone]);

  const onSubmit = async () => {
    const form = document.getElementById('phoneForm') as HTMLFormElement;

    if (form) {
      form.submit();
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    errors,
    setErrors,
  };
};
