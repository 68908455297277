import React, { useEffect, useMemo, useState } from 'react';

export type Tab = {
  title: string;
  Content: React.FC;
};

type Props = {
  tabs: Tab[];
  current?: number;
};

const Tabs: React.FC<Props> = ({ tabs, current }) => {
  const [activeTab, setActiveTab] = useState(current ?? 0);
  const Tab = useMemo(() => tabs[activeTab], [tabs, activeTab]);
  useEffect(() => {
    if (current !== undefined) {
      setActiveTab(current);
    }
  }, [current]);

  return (
    <>
      <ul
        className='flex gap-4 items-center justify-start mt-10 w-full relative px-4'
        style={{ bottom: '-1px' }}
      >
        {tabs.map((tab, i) => (
          <li
            className={`${
              activeTab == i ? 'tab-active' : 'tab'
            } cursor-pointer text-2xl leading-relaxed`}
            key={tab.title}
            onClick={() => setActiveTab(i)}
          >
            {tab.title}
          </li>
        ))}
      </ul>
      <div className='w-full pt-14'>
        <Tab.Content />
      </div>
    </>
  );
};
export default Tabs;
