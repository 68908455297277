import { DateSelectArg, EventInput } from '@fullcalendar/react';
import { useEffect, useMemo, useState } from 'react';

import { EventForm } from '@/types/AddEventForms';
import {
  AvailabilityStatus,
  createEventId,
  eventColor,
} from '@/Utils/event-utils';
import { yup } from '@/Utils/validationConfig';

const INITIAL = {
  type: 'EmptyLeg' as AvailabilityStatus,
};

const emptyLegSchema = yup.object({
  date_range: yup.object({
    from: yup.date().required(),
    to: yup.date().required(),
  }),
  flight_time: yup.number().optional(),
  departure_airport: yup.object({
    airport_code: yup.string().required(),
  }),
  arrival_airport: yup.object({
    airport_code: yup.string().required(),
  }),
  price: yup.number().positive().optional(),
});
const transientSchema = yup.object({
  date_range: yup.object({
    from: yup.date().required(),
    to: yup.date().required(),
  }),
  airport: yup.object({
    airport_code: yup.string().required(),
  }),
  event_mode: yup.boolean(),
  event_title: yup.string().when('event_mode', {
    is: true, // alternatively: (val) => val == true
    then: yup.string().required(),
    otherwise: yup.string().nullable().optional(),
  }),
  unavailable_hours: yup.boolean(),
  unavailable_start: yup.string().when('unavailable_hours', {
    is: true, // alternatively: (val) => val == true
    then: yup.string().required(),
    otherwise: yup.string().nullable().optional(),
  }),
  unavailable_end: yup.string().when('unavailable_hours', {
    is: true, // alternatively: (val) => val == true
    then: yup.string().required(),
    otherwise: yup.string().nullable().optional(),
  }),
});

export const useAddEventForm = (
  afterSubmit: () => void,
  info?: DateSelectArg,
) => {
  const [dateRange, setDateRange] = useState({
    from: undefined as Date | undefined,
    to: undefined as Date | undefined,
  });
  const initialValues = useMemo(
    () =>
      ({
        ...INITIAL,
        date_range: dateRange,
        unavailable_start: undefined,
        unavailable_end: undefined,
        event_title: undefined,
      }) as EventForm,
    [dateRange],
  );

  useEffect(() => {
    if (!info?.startStr) {
      return;
    }
    setDateRange({ from: info?.start, to: info?.end });
    // setValues(v => ({ type: v.type, start: info.startStr, end: info.endStr }));
  }, [info]);

  const onSubmit = (values: EventForm, formikHelpers: any) => {
    const calendarApi = info?.view?.calendar;
    if (!calendarApi) return;
    calendarApi.unselect(); // clear date selection
    const start = values.date_range.from;
    const end = values.date_range.to;

    const allDay =
      info?.allDay && !['Maintenance', 'EmptyLeg'].includes(values.type);

    console.log({ allDay, values: values.date_range });

    // adding the selected time to the date objects
    if (!allDay) {
      let h = parseInt(values.date_range.from_time.substr(0, 2));
      let m = parseInt(values.date_range.from_time.substr(3, 2));
      h =
        h + (values.date_range.from_time.toLowerCase().includes('pm') ? 12 : 0);
      start.setHours(h, m);
      h = parseInt(values.date_range.to_time.substr(0, 2));
      h = h + (values.date_range.to_time.toLowerCase().includes('pm') ? 12 : 0);
      m = parseInt(values.date_range.to_time.substr(3, 2));
      end.setHours(h, m);
    }

    if (values) {
      const event: EventInput = {
        id: createEventId(),
        title: values.type,
        start,
        end,
        allDay,
        color: eventColor(values.type),
        extendedProps: values,
      };
      // [x]: Remove repeating events feature
      // if (values.repeating) {
      //   event.daysOfWeek = [start.getDay()];
      //   event.startRecur = event.start;
      //   event.endRecur =
      //     event.start?.toString() !== event.end?.toString()
      //       ? event.end
      //       : undefined;
      //   event.groupId = event.id;
      // }

      calendarApi.addEvent(event);
      afterSubmit();
    }
  };

  const validate = async (values: EventForm) => {
    try {
      if (values.type == 'EmptyLeg') {
        await emptyLegSchema.validate(values, { abortEarly: false });
      }
      if (values.type == 'Transient') {
        await transientSchema.validate(values, { abortEarly: false });
      }
      return {};
    } catch (errors) {
      return errors.inner
        .map((err: { path: string; message: string }) => extractErrors(err))
        .reduce((acc: any, curr: any) => {
          return { ...curr, ...acc };
        }, {});
    }
  };

  return { initialValues, onSubmit, validate };
};
const extractErrors = (error: { path: string; message: string }) => {
  const obj = {};
  const pathParts = error.path.split('.');
  if (pathParts.length == 1) {
    obj[pathParts[0]] = error.message;
  } else {
    obj[pathParts[0]] = { [pathParts[1]]: error.message };
  }
  return obj;
};
