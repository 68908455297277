import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useMemo, useState } from 'react';

import ReactQueryProvider from '@/API/ReactQueryProvider';
import { useGetClientSecret } from '@/API/stripeApi';
import CheckboxBtn from '@/components/CheckboxBtn';
import CardDetailsForm from '@/components/Operator/Stripe/CardDetailsForm';
import PreviousBillingForm, {
  PreviousBillingFormProps,
} from '@/components/Operator/Stripe/PreviousBillingForm';
import SkeletonBlock from '@/components/Skeleton/SkeletonBlock';

type Props = PreviousBillingFormProps & {
  stripeKey: string;
  clientSecretUrl: string;
  return_url: string;
  action: string;
};
type PaymentMethod = 'PREVIOUS' | 'ADD_CARD';

const StripePayment: React.FC<Props> = ({
  stripeKey,
  clientSecretUrl,
  cards,
  return_url,
  ...other
}) => {
  const stripePromise = useMemo(() => loadStripe(stripeKey), []);
  const hasCards = cards.length > 0;

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    hasCards ? 'PREVIOUS' : 'ADD_CARD',
  );
  const { refetch, isFetched, isLoading } = useGetClientSecret(
    clientSecretUrl,
    {
      enabled: !hasCards,
    },
  );
  //  'pk_test_ZxLVbKUbPiNuc482rkcVKiJw'
  //  'seti_1KgDw42HOgqnzMvwGqtH2r5c_secret_LMxrUhCDpkEdZP6JPsNP1oVIeMFKCDJ',
  const [clientSecret, setClientSecret] = useState<string>();
  const addCard = async () => {
    setPaymentMethod('ADD_CARD');
    if (!isFetched) {
      const { data, isError } = await refetch();
      if (isError || !data?.success) {
        return;
      }
      setClientSecret(data?.client_secret);
    }
  };
  useEffect(() => {
    cards.length == 0 && addCard();
  }, []);

  return (
    <div className='p-10 m-10'>
      {hasCards && (
        <>
          <CheckboxBtn
            isActive={paymentMethod === 'PREVIOUS'}
            onClick={() => setPaymentMethod('PREVIOUS')}
          >
            Use Previous Billing Method
          </CheckboxBtn>
          {paymentMethod === 'PREVIOUS' && (
            <PreviousBillingForm cards={cards} {...other} />
          )}
        </>
      )}
      <CheckboxBtn isActive={paymentMethod === 'ADD_CARD'} onClick={addCard}>
        Add Card
      </CheckboxBtn>
      {paymentMethod === 'ADD_CARD' &&
        (!clientSecret || isLoading ? (
          <SkeletonBlock
            width={'100%'}
            height={200}
            color='bg-gray-100'
            placeholder='Please Wait ...'
          />
        ) : (
          <Elements
            stripe={stripePromise}
            options={{
              loader: 'always',
              clientSecret,
              appearance: { theme: 'stripe' },
            }}
          >
            <CardDetailsForm return_url={return_url} />
          </Elements>
        ))}
    </div>
  );
};

const Wrapper = ({ children }) => {
  return <ReactQueryProvider>{children}</ReactQueryProvider>;
};
export default (props: Props) => (
  <Wrapper>
    <StripePayment {...props} />
  </Wrapper>
);
