import { Field,Form, Formik } from 'formik';
import React from 'react';

import CodeInput from '@/components/Forms/CodeInput';
import { Props } from '@/pages/PhoneCodeVerification';
import { usePhoneCodeForm } from '@/Utils/formHooks/usePhoneCodeForm';
import { useCSRFToken } from '@/Utils/useCSRFToken';

import Timer from './Timer';

const CodeForm: React.FC<Omit<Props, 'blocked_until'>> = ({
  sms_attempts,
  sms_sent_at,
  phone,
  verification_error,
}) => {
  const {
    handleOnFocus,
    handleMoveToStart,
    verificationError,
    ...validationCode
  } = usePhoneCodeForm(verification_error);
  const csrfToken = useCSRFToken();

  return (
    <>
      <p className='w-full font-normal p-0 pb-6 leading-8 text-14px pt-4 text-textGrey'>
        Enter the 6-digit verification code we sent to phone number ending with{' '}
        {phone ? '***' + phone.slice(-4) : '****'}
      </p>

      <Formik {...validationCode}>
        <Form
          id='codeForm'
          action='/users/verify_phone_number'
          method='POST'
          className='w-full flex flex-col items-center'
        >
          <input type='hidden' name='authenticity_token' value={csrfToken} />

          <Field name='code'>
            {({ field }) => (
              <>
                <CodeInput
                  {...field}
                  smsAttempts={sms_attempts ?? 0}
                  name='code'
                  label='Please enter the code below'
                  required
                  className='p-0'
                  verificationError={verificationError}
                  handleOnFocus={handleOnFocus}
                  handleMoveToStart={handleMoveToStart}
                />
              </>
            )}
          </Field>
          <button
            className='btn bg-customBlue rounded-lg w-full mt-8 text-14px'
            type='submit'
          >
            Verify
          </button>
        </Form>
      </Formik>

      <Timer smsSentAt={sms_sent_at ?? ''} phone={phone ?? ''} />
    </>
  );
};
export default CodeForm;
