import React, { useRef, useState } from 'react';
import { FiGrid, FiList } from 'react-icons/fi';
import {
  LazyLoadComponent,
  ScrollPosition,
  trackWindowScroll,
} from 'react-lazy-load-image-component';

import { Emptyleg } from '@/API/models';
import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';
import { elementInViewport } from '@/Utils/helpers';
import useMediaQuery from '@/Utils/useMediaQuery';

type Props = {
  data?: Emptyleg[];
  isLoading: boolean;
  selected?: number;
  showOnMap: (i: number) => void;
  scrollPosition: ScrollPosition;
};

const EmptyLegsResults: React.FC<Props> = ({
  data = [],
  isLoading,
  selected,
  scrollPosition,
  showOnMap,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const loopRef = useRef<NodeJS.Timer | null>(null);
  const [copyState, setCopyState] = useState<'Copy' | 'Copied'>('Copy');
  const [view, setView] = useState('grid');

  const isMobile = useMediaQuery('(max-width: 640px)');

  React.useEffect(() => {
    // on mobile is map is not visible -> scroll to top
    const window_height =
      $(window).height() ?? window.innerHeight ?? visualViewport.height;
    if (selected !== undefined && isMobile && window.scrollY > window_height) {
      return window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }

    if (selected === undefined || !ref.current) return;
    const resultsList = ref.current;
    const selectedCard = resultsList.children[selected];
    if (!selectedCard) return;

    // scroll to the flight selected from the map
    selectedCard.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });

    // keep scrolling until the element is loaded and visible on the viewport
    loopRef.current = setInterval(() => {
      const selectedCard = ref.current?.children[selected]!;
      if (!selectedCard) {
        return clearInterval(loopRef.current!);
      }
      const isDiv = $(selectedCard).is('div');

      if (isDiv && elementInViewport(selectedCard)) {
        return clearInterval(loopRef.current!);
      }
      selectedCard.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }, 500);
    return () => clearInterval(loopRef.current!);
  }, [selected, ref.current]);

  return (
    <div className='p-8 pt-0'>
      {!isLoading ? (
        <div className='flex flex-row md:flex-col items-center md:justify-between md:items-start md:flex-nowrap gap-4 flex-wrap'>
          <p>{data.length} results were found!</p>
          <div className='flex flex-nowrap gap-4 justify-start'>
            <div className='w-full flex gap-4'>
              <div className='flex'>
                <div className='bg-white box-border rounded-l-lg h-40px border border-solid border-gray-300 text-gray-400  whitespace-nowrap overflow-hidden p-4 flex items-center md:max-w-md max-w-xs'>
                  {window.location.href}
                </div>
                <button
                  className='btn btn-dimmed w-40 rounded-none rounded-r-lg'
                  onClick={async () => {
                    await navigator.clipboard.writeText(window.location.href);
                    setCopyState('Copied');
                    setTimeout(() => {
                      setCopyState('Copy');
                    }, 400);
                  }}
                >
                  {copyState}
                </button>
              </div>
              <div className='md:flex md:gap-4 hidden'>
                <button onClick={() => setView('list')} className='btn'>
                  <FiList />
                </button>
                <button onClick={() => setView('grid')} className='btn'>
                  <FiGrid />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full flex items-stretch justify-center flex-col'>
          <p>loading your results. please wait ...</p>
          <Skeleton />
        </div>
      )}
      {!isLoading && (
        <div ref={ref} className={`results-${view} grid gap-10 my-5`}>
          {data.length > 0 &&
            data.map((el, i) => (
              <LazyLoadComponent
                scrollPosition={scrollPosition}
                // afterLoad={() => {
                //   console.group('card' + i);
                //   console.log('loaded');
                //   console.groupEnd();
                // }}
                key={el._id ?? el.emptyleg_id ?? i}
              >
                <Card
                  selected={selected == i}
                  {...el}
                  onClick={() => showOnMap(i)}
                />
              </LazyLoadComponent>
            ))}
        </div>
      )}
    </div>
  );
};
// export default EmptyLegsResults;
export default trackWindowScroll(EmptyLegsResults);
