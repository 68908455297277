import React, { useMemo } from 'react';
import { BsFillPeopleFill } from 'react-icons/bs';
import {
  FiCalendar,
  FiChevronRight,
  FiClock,
  FiInfo,
  FiMapPin,
} from 'react-icons/fi';
import { GiCommercialAirplane } from 'react-icons/gi';

import { Emptyleg } from '@/API/models';
import { useCreateFlight } from '@/API/searchEmptyLegs';
import ImageSlider from '@/components/ImageSlider';
import Modal from '@/components/Modal';
import Popover from '@/components/Popover';
import LogoFilled from '@/components/SVG/logoFilled';
import { cleanDate, createDateObj, formatTime } from '@/Utils/helpers';

type Props = Emptyleg & {
  selected?: boolean;
  operator_view?: boolean;
  displayOnly?: boolean; // used in the aside
  showOperator?: boolean; // used in the aside
  onClick?: any;
};

const PriceDisplay: React.FC<{
  isPresent: boolean;
  price: string;
  originalPrice: string;
}> = ({ isPresent, originalPrice, price }) => {
  if (!isPresent) {
    return <p>TBD</p>;
  }
  if (originalPrice === price) {
    return <p>{price}</p>;
  }
  return (
    <Popover
      Btn={() => (
        <span className='flex items-center'>
          <FiInfo className='mx-4' />
          <p>{price}</p>
        </span>
      )}
      content={'converted price from ' + originalPrice}
    />
  );
};

const FlightBtns: React.FC<{ emptyLeg: Emptyleg }> = ({ emptyLeg }) => {
  const {
    evolux_price,
    evolux_skyshare_price,
    original_evolux_skyshare_price,
    original_evolux_price,
    price,
  } = emptyLeg;
  const createFlightApi = useCreateFlight();
  const createFlight = async (booking_type: 'skylimo' | 'skyshare') => {
    // return (
    //   '/flights/create_flyeasy_flight?' +
    //   $.param({ flight: emptyLeg, booking_type })
    // );
    const res = await createFlightApi.mutateAsync({ emptyLeg, booking_type });
    return window.location.assign(res.data?.redirect_url);
  };

  return (
    <>
      <Modal isOpen={createFlightApi.isLoading}>
        <p className='leading-relaxed capitalize'>
          creating your flight request please wait ...
        </p>
      </Modal>
      <div
        // animation using .slide-up in the scss file
        className='slide-up absolute bottom-2 left-0 right-0
    text-xl rounded-md overflow-hidden flex items-center justify-center
    transform transition-transform'
      >
        <button
          className='price-btn p-5 pb-2'
          onClick={() => createFlight('skyshare')}
        >
          <img className='mb-2' src={require('@images/SKYSHARE.svg')} />
          <PriceDisplay
            isPresent={price > 1}
            originalPrice={`${original_evolux_skyshare_price.replace(
              '.00',
              '',
            )}/seat`}
            price={`${evolux_skyshare_price.replace('.00', '')}/seat`}
          />
          <span className='text-sm'>Check Availability</span>
        </button>
        <button
          className='price-btn p-5 pb-2'
          onClick={() => createFlight('skylimo')}
        >
          <img className='mb-2' src={require('@images/SKYPRIVATE.svg')} />
          <PriceDisplay
            isPresent={price > 1}
            originalPrice={original_evolux_price}
            price={evolux_price}
          />
          <span className='text-sm'>Check Availability</span>
        </button>
      </div>
    </>
  );
};

const Card: React.FC<Props> = ({
  selected,
  displayOnly = false,
  showOperator = false,
  operator_view = false,
  onClick,
  ...emptyLeg
}) => {
  const {
    departureAirport,
    arrivalAirport,
    date1,
    date2,
    time_zone,
    evolux,
    operator,
    ac: { tail, title, typeJson, images = [], pax = '', featureList = [] },
    flightTimeDec,
  } = emptyLeg;
  const dateRange = useMemo(() => {
    return {
      earliest: createDateObj(date1),
      latest: createDateObj(date2),
    };
  }, []);

  // console.log((evolux ? ' EVOLUX ' : ' ') + (id ? id : ''));

  return (
    <div
      className={`my-card overflow-hidden shadow-md rounded-md ${
        selected ? 'ring-4' : ''
      }`}
    >
      <div className='block relative h-96 rounded-md overflow-hidden'>
        <ImageSlider images={images} />
        <div
          className={`absolute top-2 right-2 flex items-center justify-center gap-4`}
        >
          {evolux && !displayOnly && (
            <h2>
              <Popover
                content='Created on EvoLux'
                Btn={() => (
                  <LogoFilled className='w-10 h-10 text-gray-100 fill-current filter drop-shadow-sm' />
                )}
              />
            </h2>
          )}
          <h2
            className={`text-white font-black text-2xl opacity-80 p-2 min-w-min w-40 text-center rounded-md`}
            style={{ backgroundColor: typeJson.color }}
          >
            {typeJson.label}
          </h2>
        </div>

        {!displayOnly && <FlightBtns emptyLeg={emptyLeg} />}
      </div>
      <div className='mt-4 flex justify-between p-5'>
        <div className='max-w-min'>
          <h4 className='text-2xl leading-relaxed mr-2'>{`${title ?? ''}`}</h4>
          {operator_view && (
            <h4 className='text-2xl whitespace-nowrap'>{`#${tail}`}</h4>
          )}
          <h2 className='text-gray-900 title-font text-6xl font-medium flex items-center'>
            <div>
              {departureAirport.airport_code}
              <p className='text-gray-500 text-xl'>
                {departureAirport.location}
              </p>
            </div>
            <FiChevronRight className='self-center flex-shrink-0' size={20} />
            <p className='text-gray-500 text-xl text-center'>
              {flightTimeDec ? flightTimeDec + 'hrs' : 'TBD'}
            </p>
            <FiChevronRight className='self-center flex-shrink-0' size={20} />
            <div>
              {arrivalAirport.airport_code}
              <p className='text-gray-500 text-xl'>{arrivalAirport.location}</p>
            </div>
          </h2>
          <h4 className='text-xl mt-4'>Available to depart this location:</h4>
          <h4 className='text-2xl flex justify-between'>
            {dateRange.earliest.date}
            <FiCalendar />
            {dateRange.latest.date}
          </h4>
          <h4 className='text-2xl flex justify-between'>
            {`${dateRange.earliest.time} ${time_zone}`}
            <FiClock />
            {`${dateRange.latest.time} ${time_zone}`}
          </h4>
        </div>
        <div className='w-1/2 text-right text-2xl flex flex-col items-end'>
          <h4 className='flex'>
            <span className='mx-4'>
              Max {pax}
              <BsFillPeopleFill />
            </span>
          </h4>
          <Popover
            Btn={() => (
              <h4 className='flex items-center'>
                <FiInfo className='mx-4' /> Amenities({featureList.length})
              </h4>
            )}
            content={
              featureList.length ? featureList.join('\n') : 'not available'
            }
          />
        </div>
      </div>
      {showOperator && (
        <p
          className={`text-white font-thin text-3xl p-4 min-w-min text-center rounded-b-md bg-red-400 break-words`}
        >
          <GiCommercialAirplane /> {operator.name}
        </p>
      )}
      {!displayOnly && (
        <p
          onClick={onClick}
          className={`text-gray-700 font-thin p-2 min-w-min text-center rounded-b-md bg-gray-200 break-words cursor-pointer`}
        >
          <FiMapPin />
          show departure/arrival on map
        </p>
      )}
    </div>
  );
};
export default React.memo(Card, (prev, next) => {
  return prev._id !== next._id;
});
