import React from 'react';

type Props = {
  isVerified?: string | null;
  handleVerifyNow?: () => void;
};

export const VerifiedIdentifier: React.FC<Props> = ({
  isVerified,
  children,
  handleVerifyNow,
}) => {
  return isVerified ? (
    <>
      <div className='bg-lightGreen flex justify-center items-center w-16 h-16 rounded-full'>
        <img src='/icons/ok.svg' alt='check mark symbol' />
      </div>
      <span className='text-16px text-customBlack pl-6'>Verified</span>
    </>
  ) : (
    <>
      <div className='bg-pink  flex justify-center items-center w-16 h-16 rounded-full'>
        <img src='/icons/warning.svg' alt='warning symbol' />
      </div>
      <span className='text-16px text-customBlack pl-6 '>
        Pending Verification
      </span>
      <button
        type='button'
        onClick={handleVerifyNow}
        className='text-16px bg-transparent text-customBlue pl-6 underline decoration-solid cursor-pointer'
      >
        {children}
      </button>
    </>
  );
};
