import React, { useCallback } from 'react';
import { FiXCircle } from 'react-icons/fi';
import ReactModal, { Props as ModalProps } from 'react-modal';

type Props = ModalProps & { noScrollLock?: boolean };
ReactModal.setAppElement('body');

const Modal: React.FC<Props> = ({
  children,
  className,
  noScrollLock = false,
  ...props
}) => {
  const onAfterOpen = useCallback(() => {
    if (noScrollLock) {
      return;
    }
    const scrollTop = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = '100vw';
    document.body.style.top = scrollTop;
  }, [noScrollLock]);
  const onAfterClose = useCallback(() => {
    if (noScrollLock) {
      return;
    }
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }, [noScrollLock]);
  return (
    <ReactModal
      closeTimeoutMS={200}
      className={`bg-white relative shadow-sm p-5 md:px-20 md:py-10 mx-16 rounded-md md:w-3/4 w-full outline-none ${className}`}
      style={{ content: { maxHeight: '90vh', overflow: 'auto' } }}
      overlayClassName='bg-gray-500 flex justify-center items-center fixed overflow-y-scroll bg-opacity-50 inset-0'
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      shouldCloseOnOverlayClick={true}
      onRequestClose={props.onRequestClose}
      {...props}
    >
      {props.onRequestClose && (
        <div className='absolute top-3 right-3 z-100 '>
          <img
            src='/icons/close-icon.svg'
            alt='Close symbol'
            className='cursor-pointer'
            onClick={props.onRequestClose}
          />
        </div>
      )}
      {children}
    </ReactModal>
  );
};
export default Modal;
