import { Field,Form, Formik } from 'formik';
import React from 'react';

import { PhoneInput } from '@/components/Forms';
import { Props } from '@/pages/PhoneNumberVerification';
import { usePhoneNumberForm } from '@/Utils/formHooks/usePhoneNumberForm';
import { useCSRFToken } from '@/Utils/useCSRFToken';

const PhoneForm: React.FC<Props> = ({ phone }) => {
  const { errors, setErrors, ...validationPhone } = usePhoneNumberForm(
    phone ?? '',
  );
  const csrfToken = useCSRFToken();

  return (
    <section className='bg-white flex w-full min-h-50vh justify-center '>
      <div className='py-16 max-w-400 flex flex-col '>
        <p className='w-full font-normal p-0 leading-6 text-14px text-textGrey'>
          For security, Evolux needs to verify your identity.
        </p>
        <p className='w-full font-normal p-0 pb-6 leading-6 text-14px pt-4 text-textGrey'>
          We’ll send a 6-digit verification code via text message.
        </p>
        <Formik {...validationPhone}>
          <Form
            id='phoneForm'
            action='/users/send_phone_code'
            method='POST'
            className='w-full flex flex-col items-center'
          >
            <input type='hidden' name='authenticity_token' value={csrfToken} />

            <Field name='phone'>
              {({ field }) => (
                <PhoneInput
                  {...field}
                  label='Phone number'
                  className='w-full p-0 rounded-lg'
                  required
                />
              )}
            </Field>

            <button
              className='btn bg-customBlue rounded-lg w-full mt-8'
              type='submit'
            >
              Send Code
            </button>
          </Form>
        </Formik>
      </div>
    </section>
  );
};

export default PhoneForm;
