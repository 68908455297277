import React from 'react';

export const BellIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
    >
      <path
        className='bell_icon'
        d='M8.99498 0.247345C13.0449 0.247345 16.3517 3.44209 16.4908 7.4964L16.495 7.74734V11.8444L17.875 15.0004C17.9441 15.1583 17.9797 15.3288 17.9797 15.5012C17.9797 16.1915 17.4201 16.7512 16.7297 16.7512L11.995 16.7527C11.995 18.4095 10.6519 19.7527 8.99498 19.7527C7.39728 19.7527 6.09133 18.5037 6.00008 16.9289L5.99453 16.7504L1.26986 16.7512C1.09852 16.7512 0.929005 16.7159 0.771845 16.6477C0.138645 16.3726 -0.151705 15.6364 0.123335 15.0032L1.49499 11.8453V7.74724C1.49559 3.59245 4.84708 0.247345 8.99498 0.247345ZM10.4946 16.7504L7.49498 16.7527C7.49498 17.5811 8.16658 18.2527 8.99498 18.2527C9.77468 18.2527 10.4155 17.6578 10.4881 16.8971L10.4946 16.7504ZM8.99498 1.74734C5.67483 1.74734 2.99547 4.42161 2.99499 7.74734V12.157L1.65101 15.2512H16.3475L14.995 12.158L14.9951 7.76021L14.9914 7.535C14.8803 4.30152 12.2366 1.74734 8.99498 1.74734Z'
        fill='white'
      />
    </svg>
  );
};
