import React from 'react';

type Props = {};

const BookingCard: React.FC<Props> = ({}) => {
  return (
    <li className='<%= booking.flight.status_class %>'>
      <a href=''>
        {/* <%= link_to trip_path(booking.flight) do %> */}
        <header>
          <h2>
            <strong>
              {' '}
              Date
              {/* <%= booking.flight.departure_datetime.to_formatted_s(:rfc822) %> */}
            </strong>
            {/* <%= booking.flight.title %> */}
            Title
            <mark>{/* <%= booking.flight.status_title %> */}status</mark>
          </h2>
          <div className='price'>{/* <%= booking.booking_price %> */}Price</div>
        </header>
        <div className='details'>
          {/* <%= image_tag(booking.flight.destination_image.url) if booking.flight.destination_image.present? %> */}
          <h3>
            {/* <%= booking.flight.departure_code %> */}FROM
            <i className='fa fa-angle-right'></i>
            {/* <%= booking.flight.arrival_code %> */}TO
          </h3>
          <dl>
            {/* <% if booking.flight.has_stops? %> */}
            <dt title='Stop'> g</dt>
            <dd>{/* <%= booking.flight.flight_stops_text %> */} Stops</dd>
            {/* <% end %> */}
            <dt title='Helicopter'> e</dt>
            <dd>{/* <%= booking.aircraft_craft_model_name %> */}ac model</dd>
            <dt title='Passengers'> b</dt>
            <dd>{/* <%= booking.passengers.size %> */}size</dd>
          </dl>
        </div>
      </a>
      {/* <% end %> */}
    </li>
  );
};
export default BookingCard;
