import { useMemo } from 'react';

type Args = {
  price: number;
  pricing: object;
  nbr_passengers: number;
  price_config: {
    evolux_margin: number;
    passenger_margins: {
      [key: number]: number;
    };
  };
};

export const useGetSeatPrice = ({
  price,
  pricing,
  nbr_passengers,
  price_config,
}: Args) => {
  const seatPrice = useMemo(() => {
    // to avoid errors
    if (!nbr_passengers || !price) {
      return undefined;
    }
    // Posted Price
    if (pricing && pricing[nbr_passengers]) {
      return pricing[nbr_passengers];
    }

    const evoluxMargin = price_config.evolux_margin;
    const totalMargin = price_config.passenger_margins[nbr_passengers];

    // invalid nbr_passengers
    if (!totalMargin) {
      return undefined;
    }

    // TBD
    const calculated =
      (price / (nbr_passengers * (1 + evoluxMargin))) * (1 + totalMargin);
    return Math.ceil(calculated);
  }, [nbr_passengers, price]);
  return seatPrice;
};
