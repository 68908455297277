import { useState } from 'react';

import { yup } from '@/Utils/validationConfig';

export const usePhoneCodeForm = (verification_error: string | null) => {
  const [verificationError, setVerificationError] = useState<string | null>(
    verification_error,
  );
  const handleOnFocus = () => setVerificationError(null);
  const handleMoveToStart = (e) => {
    const inputElement = e.target as HTMLInputElement;
    inputElement.setSelectionRange(0, 0);
  };
  const validationSchema = yup.object({
    code: yup
      .string()
      .matches(/^\d{6}$/, 'Code must be 6 digits')
      .required('Code is required'),
  });

  const initialValues = {
    code: '',
  };

  const onSubmit = async () => {
    const form = document.getElementById('codeForm') as HTMLFormElement;

    if (form) {
      form.submit();
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    verificationError,
    handleOnFocus,
    handleMoveToStart,
  };
};
