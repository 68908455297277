import React from 'react';
const Facebook = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='18'
      viewBox='0 0 10 18'
      fill='none'
    >
      <path
        d='M2.75955 17.8862H6.33839V8.92469H8.83505L9.10144 5.92405H6.33839C6.33839 5.92405 6.33839 4.80365 6.33839 4.21493C6.33839 3.50769 6.48072 3.22801 7.1647 3.22801C7.7152 3.22801 9.10144 3.22801 9.10144 3.22801V0.113831C9.10144 0.113831 7.05947 0.113831 6.62361 0.113831C3.9608 0.113831 2.7601 1.28684 2.7601 3.53151C2.7601 5.48707 2.7601 5.92405 2.7601 5.92405H0.898682V8.96235H2.7601L2.75955 17.8862Z'
        fill='white'
      />
    </svg>
  );
};
export default Facebook;
