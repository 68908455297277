import React from 'react';
import { FiArrowUpCircle } from 'react-icons/fi';

type Props = {};

const FloatingBtn: React.FC<Props> = () => {
  return (
    <button
      className='fixed flex bottom-5  left-5 z-20 rounded-full border-none bg-white bg-opacity-80 text-gray-700 shadow'
      onClick={() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }}
    >
      <FiArrowUpCircle size={40} />
    </button>
  );
};

export default FloatingBtn;
