import React, { useCallback, useEffect, useRef } from 'react';

type Props = {
  errors?: string[];
  signIn?: boolean;
  setErrors?: React.Dispatch<React.SetStateAction<string[]>>;
  className?: string;
};

const ErrorsTooltip: React.FC<Props> = ({
  errors,
  setErrors,
  signIn = false,
  className = '',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!errors?.length || !ref.current) {
      return;
    }
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [errors, ref.current]);
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setErrors && setErrors([]);
    }
  };
  const errorMessage = useCallback(
    (err: string) =>
      signIn && err.includes('already been taken')
        ? `${err} - please try signing in or resetting password.`
        : err,
    [signIn],
  );
  if (!errors) return null;
  return (
    <div ref={ref} className={`flex gap-4 w-full flex-col mb-4 ${className}`}>
      {errors.map((err, i) => (
        <div
          key={i}
          className='bg-red-200 border-2 border-solid border-red-400 p-4 rounded-md'
        >
          {errorMessage(err)}
        </div>
      ))}
    </div>
  );
};
export default ErrorsTooltip;
