import { FormikHandlers, FormikHelpers } from 'formik';
import { useMemo } from 'react';

import { Airport } from '@/API/airportApi';
import api from '@/API/config';
import { Flight } from '@/API/models';
import { ChangeFlightProps } from '@/components/Operator/ChangeFlight';
import { formatDate, strDate } from '@/Utils/helpers';
import { yup } from '@/Utils/validationConfig';

const validation = yup.object({
  departure_date: yup.date().required(),
  departure_time: yup.string().required(),
  flight_time: yup.number().required(),
  departure_airport_code: yup.object({ airport_code: yup.string().required() }),
  arrival_airport_code: yup.object({ airport_code: yup.string().required() }),
  aircraft_emptyleg_price: yup.number().positive().required(),
  nbr_passengers: yup.number().positive().optional(),
  reason: yup.string().required(),
  fees_attributes: yup.array(
    yup.object({
      name: yup.string().required(),
      amount: yup.number().positive().required(),
    }),
  ),
});
export const useChangeFlightForm = (flight: ChangeFlightProps) => {
  const initialValues = useMemo(() => {
    const time = flight.departureTime;
    const [hh_mm, am_pm, timezone] = time.split(' ');

    // calculate basePrice without fees
    const initialFees = flight.fees.reduce((prev, acc) => prev + acc.amount, 0);
    const basePrice = flight.price - initialFees;

    return {
      departure_date: strDate(flight.departureDate),
      timezone,
      departure_time: [hh_mm, am_pm].join(' '),
      flight_time: flight.flightTime,
      departure_airport_code: { airport_code: flight.departureCode } as Airport,
      departure_airport_city: flight.departureCity,
      arrival_airport_code: { airport_code: flight.arrivalCode } as Airport,
      arrival_airport_city: flight.arrivalCity,
      nbr_passengers: flight.nbrPassengers,
      aircraft_emptyleg_price: basePrice,
      reason: flight.reason,
      fees_attributes: flight.fees,
    };
  }, []);

  type VALUES = typeof initialValues;

  const realOnSubmit = async (values: VALUES, touched: any) => {
    // process values ....
    const data = {
      ...values,
      departure_airport_code: values.departure_airport_code.airport_code,
      arrival_airport_code: values.arrival_airport_code.airport_code,
      departure_date: formatDate(values.departure_date),
    };

    const { data: response } = await api.put<{
      redirect_to: string;
      success: boolean;
    }>(flight.action, {
      change: data,
    });
    if (response.redirect_to) {
      window.location.assign(response.redirect_to);
    }
  };

  return { initialValues, validation, onSubmit: () => {}, realOnSubmit };
};
