import React from 'react';
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  svgLeft?: React.ReactNode;
  svgRight?: React.ReactNode;
};
const Button: React.FC<ButtonProps> = (props) => {
  const {
    type = 'button',
    className = 'btn ',
    children,
    onClick,
    svgLeft,
    svgRight,
    ...restProps
  } = props;
  return (
    <button
      onClick={onClick}
      className={`w-full ${className}`}
      type={type}
      {...restProps}
    >
      {svgLeft}
      {children}
      {svgRight}
    </button>
  );
};

export default Button;
