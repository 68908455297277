import React, { useMemo } from 'react';

import { Emptyleg } from '@/API/models';

type Props = Emptyleg & {
  onSelect: (i?: number) => void;
  routes: Emptyleg[];
};

const DisplayInfo: React.FC<Props> = ({
  departureAirport,
  arrivalAirport,
  date1,
  onSelect,
  evolux_price,
  evolux_skyshare_price,
  ac,
  routes,
}) => {
  const samePathArr = useMemo(
    () =>
      routes
        .map((r, idx) => ({ ...r, idx }))
        .filter(
          (r) =>
            r.departureAirport.airport_code === departureAirport.airport_code &&
            r.arrivalAirport.airport_code === arrivalAirport.airport_code,
        ),
    [routes, departureAirport],
  );
  return (
    <>
      <p className='font-bold'>
        {departureAirport.airport_code + ' > ' + arrivalAirport.airport_code}{' '}
      </p>
      {samePathArr.length > 1 ? (
        <>
          <p className='font-bold my-2 text-red-600'>Multiple Empty Legs</p>
          {samePathArr.map((r) => (
            <button
              key={r._id ?? r.emptyleg_id}
              className='p-0 pb-2 bg-transparent text-indigo-600 border-none block'
              onClick={() => onSelect(r.idx)}
            >
              - {r.ac.typeJson.label}
            </button>
          ))}
        </>
      ) : (
        <>
          <p> {date1.substring(0, 10)} </p>
          <p>Aircraft Type: {ac.typeJson.label}</p>
          <p>SkyPrivate: {evolux_price == '$0' ? 'TBD' : evolux_price}</p>
          <p>
            SkyShare:
            {evolux_skyshare_price == '$0'
              ? 'TBD'
              : evolux_skyshare_price + '/Seat'}
          </p>
          <p>Max: {ac?.pax ?? 'unknown'}</p>
          <button
            style={{
              padding: 0,
              background: 'none',
              color: 'dodgerblue',
              border: 'none',
            }}
            onClick={() => onSelect()}
          >
            view
          </button>
        </>
      )}
    </>
  );
};
export default DisplayInfo;
