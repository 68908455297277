import React, { AnchorHTMLAttributes } from 'react';

type Props = AnchorHTMLAttributes<HTMLAnchorElement>;

const StripeButton: React.FC<Props> = ({ children, ...other }) => {
  return (
    <a className='btn max-w-52' target='_blank' {...other}>
      {children}
    </a>
  );
};
export default StripeButton;
