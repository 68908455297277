import React from 'react';
import { FiInfo } from 'react-icons/fi';

import { AirportInput, DateRangeInput, Input } from '@/components/Forms';
import Popover from '@/components/Popover';

type Props = {};

const EmptyLegFields: React.FC<Props> = ({}) => {
  return (
    <>
      <AirportInput required name='departure_airport' />
      <AirportInput required name='arrival_airport' />
      <DateRangeInput
        label={`Availability Time And Date Range  ( departure airport timezone )`}
        name='date_range'
        withTime
        info='Please indicate the earliest and latest time this empty leg can depart this location based on the date range selected. Click on the date twice if only available at that location for certain hours on the same day.'
      />
      <Input
        name='flight_time'
        type='number'
        placeholder='the duration of the flight in hours'
        text='hours'
      />
      <Input
        type='number'
        name='price'
        preText='$.'
        info="You may leave the price blank, which will display as TBD, however the customer will be able to 'name their price' when they check the availability of this empty leg. You will have the opportunity to counter the price when responding 'Available with a change'."
      />
    </>
  );
};
export default EmptyLegFields;
