import React from 'react';

import { DateRangeInput } from '@/components/Forms';

type Props = {};

const MaintenanceFields: React.FC<Props> = ({}) => {
  // const { values, setFieldValue } = useFormikContext<EventForm>();
  // useEffect(() => {
  //   setFieldValue(
  //     'to_time',
  //     formatDate(values.date_range.to, { hour: '2-digit', minute: '2-digit' }),
  //   );
  //   setFieldValue(
  //     'from_time',
  //     formatDate(values.date_range.from, {
  //       hour: '2-digit',
  //       minute: '2-digit',
  //     }),
  //   );
  // }, []);
  return (
    <>
      <DateRangeInput label='Time And Date Range' name='date_range' withTime />
    </>
  );
};
export default MaintenanceFields;
