import React from 'react';
import { Operator, User } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import EditProfileForm from '@/components/EditProfileForm';
import { ProfileInfoProps } from '@/components/Profile/ProfileInfo';

export type EditProfileProps = ProfileInfoProps & {
  user: User;
  operator: Operator;
  user_route_preferences: any;
  newsletter_frequencies: {
    daily: number;
    weekly: number;
    monthly: number;
  }; // {"daily": 0, "weekly":1, "monthly":2}
  isEditForm?: boolean;
  handleVerify: () => void;
};

const EditProfile: React.FC<EditProfileProps> = (props) => {
  return (
    <ReactQueryProvider>
      <EditProfileForm {...props} />
    </ReactQueryProvider>
  );
};
export default EditProfile;
