import React, { ReactElement, useMemo } from 'react';

export type Step = {
  title: string;
  Content: ReactElement;
};

type Props = {
  steps: Step[];
  active: number;
  onClick?: (i: number) => void;
};

const Steps: React.FC<Props> = ({ steps, active, onClick }) => {
  const Tab = useMemo(() => steps[active], [steps, active]);

  return (
    <>
      <section className='text-gray-600 body-font w-full'>
        <div className='container mx-auto flex flex-wrap flex-col w-full'>
          <div className='flex mx-auto flex-wrap justify-around ndw-full'>
            {steps.map((step, i) => {
              return (
                <div className='flex justify-center items-start' key={i}>
                  {/* Middle Line */}

                  {i > 0 && (
                    <span
                      className={`mt-5 w-16  md:w-200px ${active >= i ? ' bg-customGreen' : 'bg-customGrey'}`}
                      style={{
                        height: '1px',
                        overflow: 'hidden',
                      }}
                    ></span>
                  )}
                  {/* Active onClick */}
                  <a
                    onClick={() => {
                      onClick?.(i);
                    }}
                    className={`cursor-pointer gap-4 px-6 justify-center sm:justify-start title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t flex-col ${
                      active == i ? ' text-customBlack' : 'text-customBlack'
                    }`}
                  >
                    {/* Circle*/}
                    <span
                      className={`text-xl rounded-full w-12 h-12 text-center mx-4 font-sans leading-lead12  ${
                        active == i
                          ? 'bg-customBlack text-white'
                          : active > i
                            ? 'bg-customGreen text-white'
                            : 'border-1 border-solid border-current box-border'
                      }`}
                      style={{ lineHeight: '3rem' }}
                    >
                      {active > i ? (
                        <img
                          className='pt-3'
                          src='/icons/mark.svg'
                          alt='Check mark'
                        />
                      ) : (
                        i + 1
                      )}
                    </span>

                    {/* Text */}
                    <span
                      className={`hidden sm:inline-block text-14px ${
                        active == i
                          ? 'text-customBlack font-bold'
                          : active > i
                            ? 'text-customBlack'
                            : ''
                      }`}
                    >
                      {step.title}
                    </span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className='w-full'>{Tab.Content}</div>
    </>
  );
};
export default Steps;
