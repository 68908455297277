import React from 'react';
const Linkedin = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
    >
      <path
        d='M19.4238 11.0359V18.0047H15.3831V11.5024C15.3831 9.86994 14.7995 8.75493 13.337 8.75493C12.2208 8.75493 11.5576 9.50591 11.2642 10.2327C11.1579 10.4924 11.1306 10.8532 11.1306 11.2172V18.0047H7.08994C7.08994 18.0047 7.14403 6.99205 7.08994 5.85222H11.1312V7.57437C11.1229 7.58774 11.1115 7.6011 11.1045 7.61447H11.1312V7.57437C11.6683 6.7483 12.6255 5.5671 14.7728 5.5671C17.4311 5.5671 19.4238 7.30389 19.4238 11.0359ZM2.86283 -0.00540161C1.48053 -0.00540161 0.576172 0.902142 0.576172 2.09416C0.576172 3.26072 1.45443 4.19434 2.80937 4.19434H2.83546C4.2445 4.19434 5.12085 3.26072 5.12085 2.09416C5.09476 0.902142 4.24578 -0.00540161 2.86283 -0.00540161ZM0.816738 18.0053H4.8561V5.85285H0.816738V18.0053Z'
        fill='white'
      />
    </svg>
  );
};
export default Linkedin;
