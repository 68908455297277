import React from 'react';

import ReactQueryProvider from '@/API/ReactQueryProvider';
import BookingList from '@/components/Bookings/BookingList';

type Props = { bookings: any[] };

const BookingsPage: React.FC<Props> = (props) => {
  return (
    <ReactQueryProvider>
      <article>
        <BookingList {...props} />
      </article>
    </ReactQueryProvider>
  );
};
export default BookingsPage;
