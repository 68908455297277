import 'react-phone-number-input/style.css';

import { useField } from 'formik';
import React, { FC } from 'react';
import InputMask from 'react-input-mask';

import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';
import InvalidCodeError from '@/components/Forms/InvalidCodeError';

type CodeInputProps = InputProps & {
  smsAttempts: number;
  verificationError: string;
  handleOnFocus: () => void;
  handleMoveToStart: () => void;
};

const MAX_SMS_ATTEMPTS = 3;

const CodeInput: FC<CodeInputProps> = (props) => {
  const { smsAttempts, verificationError, handleOnFocus, handleMoveToStart } =
    props;
  const [{ value, ...formik }, { error, touched }] = useField(props);

  return (
    <FormElement {...props}>
      <div className='flex items-baseline'>
        {props.preText && (
          <p className='pr-1 inline-block text-gray-700 font-bold'>
            {props.preText}
          </p>
        )}
        <InputMask
          mask='999999'
          className={`w-full rounded-lg text-14px  input-code ${(error && touched) || verificationError ? 'border-red-500' : ''}`}
          placeholder='______'
          type={props.type || 'text'}
          onFocus={handleOnFocus}
          {...formik}
          onClick={handleMoveToStart}
        />
        {props.children}
      </div>
      <ErrorMessage name={props.name} />

      {(smsAttempts > 0 || verificationError) && (
        <InvalidCodeError
          verificationError={verificationError}
          remainingAttempts={MAX_SMS_ATTEMPTS - smsAttempts}
        />
      )}
    </FormElement>
  );
};

export default CodeInput;
