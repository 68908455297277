import React from 'react';

import { getBackgroundClass } from '@/Utils/helpers';

const UserImg = ({ current_user }) => {
  const backgroundClass = getBackgroundClass(current_user.subscription);

  return (
    <div className='pointer'>
      <span
        className={`${backgroundClass} w-12 h-12 border-solid border-4 rounded-full flex`}
      >
        <img
          className='w-full rounded-full '
          src={current_user.logo.small.url}
          alt='logo'
        />
      </span>
    </div>
  );
};
export default UserImg;
