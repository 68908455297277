import { FieldArray, useField } from 'formik';
import React, { useMemo } from 'react';

import Accordion from '@/components/Accordion';
import { AirportInput, Input, Toggle } from '@/components/Forms';
import SavedRouteCard from '@/components/SavedRouteCard';
import { useSavedRoutesForm } from '@/Utils/formHooks/useSavedRoutesForm';

export type SavedRoutesProps = { isSignup?: boolean };

const SavedRoutes: React.FC<SavedRoutesProps> = ({ isSignup }) => {
  const { addRoute, deleteRoute, saved_routes } = useSavedRoutesForm({
    isSignup,
  });
  const [{ value }] = useField('saved_routes');

  useMemo(() => {
    saved_routes ?? value ?? [];
  }, [value, saved_routes]);

  return (
    <FieldArray name='saved_routes'>
      {({ push, remove }) => (
        <>
          <div className='justify-center flex md:flex-nowrap flex-wrap w-full gap-x-14 pt-4'>
            <AirportInput
              className='w-full md:w-1/2 p-0 '
              name='savedRoutesForm.departure_airport'
              label='Departure location'
            />
            <AirportInput
              className='w-full md:w-1/2 p-0'
              name='savedRoutesForm.arrival_airport'
              label='Arrival location'
            />
          </div>
          <div className='justify-center flex md:flex-nowrap flex-wrap w-full gap-x-14 pt-4'>
            <Input
              name='savedRoutesForm.radius'
              label='Flight Radius (1-400 miles)'
              type='number'
              min='1'
              max='400'
              className='w-full md:w-1/2 p-0'
            />
            <p className='w-full md:w-1/2 p-0'></p>
          </div>
          <Toggle
            name='savedRoutesForm.returned'
            label='return trip'
            placeholder='Automatically add return trip'
            className='w-full p-0'
            labelClassName='font-bold'
          />
          <dl className='mb-12'>
            <button
              className='btn bg-customBlue rounded-lg text-14px p-0'
              type='button'
              onClick={() => addRoute(push)}
            >
              <div className='flex items-center gap-2'>
                <span className=' px-5 py-4 font-bold'>Add route</span>
              </div>
            </button>
          </dl>
          <Accordion
            openByDefault
            title={'Saved Routes:' + ' ' + saved_routes?.length}
          >
            {!saved_routes?.length ? (
              <p className='block w-full leading-relaxed font-normal text-14px p-4 m-0'>
                No saved routes
              </p>
            ) : (
              saved_routes?.map((route, i) => (
                <SavedRouteCard
                  key={route.id}
                  {...route}
                  onRemove={() => {
                    try {
                      deleteRoute(route.id!);
                      remove(i);
                    } catch (e) {
                      console.log('error deleting route');
                    }
                  }}
                />
              ))
            )}
          </Accordion>
        </>
      )}
    </FieldArray>
  );
};
export default React.memo(SavedRoutes);
