import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';

import ErrorsTooltip from '@/components/ErrorsTooltip';
import { SubmitBtn } from '@/components/Forms';
import ContainerForInputs from '@/components/Forms/ContainerInput';
import RegisterToggles from '@/components/Forms/RegisterToggles';
import { SelectDataEntry } from '@/components/Forms/Select';
import ProfileInfo from '@/components/Profile/ProfileInfo';
import ProfileSettings from '@/components/Profile/ProfileSettings';
import ProfilePicture from '@/components/ProfilePicture';
import Tabs from '@/components/Tabs';
import { EditProfileProps } from '@/pages/EditProfile';
import { useEditProfileForm } from '@/Utils/formHooks/useEditProfileForm';

const EditProfileForm: React.FC<EditProfileProps> = ({
  user,
  operator,
  user_route_preferences,
  newsletter_frequencies,
  google_maps_api_key,
  timeZones,
}) => {
  const {
    canSmsSend,
    handleRedirectToVerify,
    isOpen,
    handleToggleModal,
    handleVerifyNow,
    errors,
    ...formData
  } = useEditProfileForm(
    user,
    operator,
    user_route_preferences,
    newsletter_frequencies,
  );

  const tabs = [
    {
      title: 'Profile Settings',
      Content: () => (
        <ProfileInfo
          isVerified={user.phone_verified_at}
          role={user.role}
          isEditForm
          handleToggleModal={handleToggleModal}
          isOpen={isOpen}
          handleVerifyNow={handleVerifyNow}
          {...{ google_maps_api_key, timeZones }}
        />
      ),
    },
    {
      title: 'Notification Settings',
      Content: () => (
        <ProfileSettings {...{ frequencies: newsletter_frequencies }} />
      ),
    },
  ];

  return (
    <Formik {...formData}>
      {({ errors: formErrors, submitCount }) => (
        <Form className='w-73 justify-center lg:w-4/5 flex flex-wrap mx-auto'>
          <ProfilePicture url={user.logo.url} tier={0} />
          <ErrorsTooltip errors={errors} />
          <Tabs
            tabs={tabs}
            current={
              submitCount > 0 && Object.keys(formErrors).length ? 0 : undefined
            }
          />
          {submitCount > 0 && Object.keys(formErrors).length ? (
            <p className='text-customRed p-2 w-full font-bold'>
              Cannot submit, the form contains errors
            </p>
          ) : null}
          <ContainerForInputs>
            <SubmitBtn text='Save Account Details' />
          </ContainerForInputs>
        </Form>
      )}
    </Formik>
  );
};
export default EditProfileForm;
