import 'react-day-picker/lib/style.css';

import { useField } from 'formik';
import React, { useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import DayPicker from 'react-day-picker/DayPicker';
import { FiCalendar } from 'react-icons/fi';

import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';

type Props = InputProps & { dateRange?: { from: Date; to: Date } };
type InnerProps = {
  validDateRange?: { from: Date; to: Date };
  open: boolean;
  onClose: () => void;
  value: Date;
  handleDayChange: (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
};

export const DateInputContent: React.FC<InnerProps> = ({
  handleDayChange,
  open,
  value,
  validDateRange,
  onClose,
}) => {
  return (
    <>
      <div
        className='absolute flex-row bg-white p-4 max-w-80-vw z-101 w-max  items-start justify-center shadow-md'
        style={{
          display: open ? 'flex' : 'none',
          maxWidth: '90vw',
          zIndex: 101,
        }}
      >
        <div>
          <DayPicker
            className='Selectable text-xl'
            numberOfMonths={2}
            selectedDays={value}
            onDayClick={handleDayChange}
            disabledDays={
              validDateRange && {
                before: validDateRange?.from,
                after: validDateRange?.to,
              }
            }
          />
        </div>
      </div>
      <div
        onClick={onClose}
        style={{
          position: 'fixed',
          display: open ? 'flex' : 'none',
          zIndex: 100,
          width: '100vw',
          height: '100vw',
          top: 0,
          left: 0,
          background: 'transparent',
        }}
      ></div>
    </>
  );
};

export const DateInput: React.FC<Props> = ({ dateRange, ...props }) => {
  const [{ value, name }, {}, { setValue }] = useField<Date>(props);
  const [open, setOpen] = useState(false);

  const handleDayChange = (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (modifiers?.disabled) {
      return;
    }
    setValue(day);
    setOpen(false);
  };

  return (
    <FormElement {...props}>
      <div className='flex flex-1 flex-wrap'>
        <div className='relative flex flex-1'>
          <button
            type='button'
            className='bg-white border border-solid border-gray-300 text-gray-500 text-left flex-1 relative h-40px'
            onClick={() => setOpen(!open)}
          >
            {value ? value.toLocaleDateString() : 'Select a date'}
            <FiCalendar className='absolute right-3' size={15} />
          </button>
          <DateInputContent
            value={value}
            handleDayChange={handleDayChange}
            validDateRange={dateRange}
            open={open}
            onClose={() => setOpen(false)}
          />
        </div>
      </div>
      <ErrorMessage name={name} />
    </FormElement>
  );
};

export const CustomDateInput: React.FC<Props & { Btn: React.FC }> = ({
  Btn,
  dateRange,
  ...props
}) => {
  const [{ value, name }, {}, { setValue }] = useField<Date>(props);
  const [open, setOpen] = useState(false);
  const handleDayChange = (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (modifiers?.disabled) {
      return;
    }
    setValue(day);
    setOpen(false);
  };

  return (
    <div className='relative'>
      <button
        type='button'
        className='bg-none appearance-none border-none p-0 m-0 relative contents'
        onClick={() => setOpen(!open)}
      >
        <Btn />
      </button>
      <DateInputContent
        value={value}
        handleDayChange={handleDayChange}
        validDateRange={dateRange}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default DateInput;
