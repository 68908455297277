import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Airport } from '@/API/airportApi';
import api from '@/API/config';
import { MutationMethod, QueryMethod } from '@/types/API';

export type Route = {
  id?: number;
  departure_airport: Airport;
  arrival_airport: Airport;
  returned: boolean;
  radius: number;
};
// react-query version
const useGetUserRoutes: QueryMethod<Route[]> = (routes, options) => {
  return useQuery(
    'routes',
    () => {
      return routes;
    },
    options,
  );
};
const useDeleteRoute: MutationMethod = (options?) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const response = api.delete(`/user_route_preferences/${id}`);
      return response;
    },
    {
      onSuccess: (data, id) => {
        queryClient.setQueryData<Route[]>('routes', (old = []) => {
          return old.filter((route) => route.id != id);
        });
      },
      ...options,
    },
  );
};
const useAddRoute: MutationMethod<any, Route> = (options?) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (route: Route) => {
      const response = await api.post(`/user_route_preferences`, {
        user_route_preference: route,
      });
      return response;
    },
    {
      onMutate: async (newRoute) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries('routes');

        // Snapshot the previous value
        const previous = queryClient.getQueryData('routes');

        // Optimistically update to the new value
        const id = -100 * Math.random();
        queryClient.setQueryData<Route[]>('routes', (old = []) => [
          { id, ...newRoute },
          ...old,
        ]);

        // Return a context object with the snapshotted value
        return { previous, id };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        queryClient.setQueryData('routes', context?.previous);
      },
      onSuccess: (response, variable, ctx) => {
        queryClient.setQueryData<Route[]>('routes', (old = []) =>
          old.map((route) => {
            if (route.id == ctx?.id) {
              route.id = response.data.user_route_preference.id;
            }
            return route;
          }),
        );
      },
      ...options,
    },
  );
};
const useUpdateAccountDetails: MutationMethod<any, object> = (options?) => {
  return useMutation(async (values) => {
    const data = new FormData();
    for (const key in values) {
      const element = values[key];
      data.append(`user[${key}]`, element);
    }
    const { data: response } = await api.post<{
      success: boolean;
      errors?: string[];
    }>(`/account/update_profile_details`, data);

    if (response.errors?.length) {
      throw new Error(response.errors[0]);
    }
    return response;
  }, options);
};

interface Response {
  success: boolean;
  signin_redirection: string;
  notice: string;
  errors?: string[];
  error?: any;
}
const useUpdateAccount: MutationMethod<Response, object> = (options?) => {
  return useMutation(async (values) => {
    const { data } = await api.put<Response>(`/account`, values);
    return data;
  }, options);
};

const useRegisterAccount = (options?) => {
  return useMutation(async (values: any) => {
    const { data } = await api.post<Response>(`/users`, values);
    return data;
  }, options);
};

export {
  useAddRoute,
  useDeleteRoute,
  useGetUserRoutes,
  useRegisterAccount,
  useUpdateAccount,
  useUpdateAccountDetails,
};
