import { useMutation, useQuery } from 'react-query';

import { Emptyleg } from '@/API/models';

import { QueryMethod } from '../types/API';
import api from './config';

interface EmptyLegsResponse {
  success: boolean;
  redirect_to?: string;
  error?: string;
  emptylegs: Emptyleg[];
}
interface EmptyLegsParams {
  departure_code: string;
  arrival_code: string;
  start_date: string;
  end_date: string;
  radius: number;
}
// react-query version
const useSearchEmptyLegs: QueryMethod<EmptyLegsResponse, EmptyLegsParams> = (
  params,
  options,
) => {
  return useQuery(
    ['search_emptylegs', JSON.stringify(params)],
    async () => {
      const { data } = await api.get<EmptyLegsResponse>(
        `/search_emptylegs/search`,
        { params },
      );
      return data;
    },
    options,
  );
};

export const useCreateFlight = (options?) => {
  return useMutation(
    async ({
      emptyLeg,
      booking_type,
    }: {
      emptyLeg: Emptyleg;
      booking_type: 'skylimo' | 'skyshare';
    }) => {
      return await api.post<{
        redirect_url: string;
        success: string;
      }>(`/flights/create_flyeasy_flight`, {
        flight: emptyLeg,
        booking_type,
      });
    },
    options,
  );
};

export default useSearchEmptyLegs;
