import React from 'react';

type Props = {
  width?: number | `${string}%`;
  height?: number | `${string}%`;
  color?: string;
  className?: string;
  placeholder?: string;
};

const SkeletonBlock: React.FC<Props> = ({
  width,
  height,
  color = 'bg-gray-300',
  className,
  placeholder,
}) => {
  return (
    <div
      style={{ width, height }}
      className={`relative rounded-md ${color} ${className} animate-pulse ${
        placeholder ? 'flex-center' : 'block'
      }`}
    >
      {placeholder}
    </div>
  );
};
export default SkeletonBlock;
