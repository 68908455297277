import React from 'react';

import { TimeInput } from '@/components/Forms';
import { useInitialTimeRange } from '@/Utils/useInitialTimeRange';

type Props = { name: string };

const TimeRangeInput: React.FC<Props> = ({ name }) => {
  useInitialTimeRange();

  return (
    <div className='flex'>
      <TimeInput name={name + '.from_time'} label='from time' />
      <TimeInput name={name + '.to_time'} label='to time' />
    </div>
  );
};
export default TimeRangeInput;
