import { ErrorMessage as EM, ErrorMessageProps } from 'formik';
import React from 'react';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ name }) => {
  return (
    <EM name={name}>
      {(error) => <p className='text-customRed py-2 text-14px'>{error}</p>}
    </EM>
  );
};
export default React.memo(ErrorMessage);
