import { useField } from 'formik';
import React from 'react';

import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';

const Input: React.FC<InputProps> = ({ preText, isRequired, ...props }) => {
  const [{ value, ...formik }, { error }] = useField(props);

  return (
    <FormElement isRequired={isRequired} {...props}>
      <div className='flex items-baseline'>
        {preText && (
          <span className='font-normal text-16px leading-[20px]'>
            {preText}
          </span>
        )}
        <input
          {...props}
          className={`placeholder-gray-400 rounded-lg text-16px h-40px w-full ${error ? 'border-customRed' : ''}`}
          placeholder={props.placeholder || ''}
          type={props.type || 'text'}
          id={props.name}
          {...formik}
          value={value ?? props.value ?? ''}
        />
        {props.text && (
          <p className='inline-block text-gray-500 font-bold'>{props.text}</p>
        )}
        {props.children}
      </div>
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
export default Input;
