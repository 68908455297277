import React from 'react';
import { FaPlane } from 'react-icons/fa';
import { FiRepeat, FiTrash2 } from 'react-icons/fi';

import { Route } from '@/API/userApi';

type Props = Route & { onRemove: () => void };

const SavedRouteCard: React.FC<Props> = ({
  departure_airport,
  arrival_airport,
  returned,
  radius,
  id,
  onRemove,
}) => (
  <div
    key={id}
    className='mt-4 flex rounded box-border w-full bg-customBackgroundPrimary capitalize text-xl items-center justify-between p-3'
  >
    <p className='flex flex-1 w-3/4 justify-around items-center flex-wrap m-0 text-center text-2xl p-4'>
      <span className='w-full font-bold sm:w-1/4 text-16px'>
        {`${departure_airport.airport_code} (${departure_airport.location})`}
      </span>
      <span className='flex-1 text-base font-normal text-customDarkGrey text-14px flex-center'>
        {returned ? (
          <FiRepeat className='mx-4' size={20} />
        ) : (
          <FaPlane className='mx-4 rotate-90 sm:rotate-0 transform' />
        )}
        ({radius}miles)
      </span>
      <span className='w-full font-bold text-16px sm:w-1/4'>
        {`${arrival_airport.airport_code} (${arrival_airport.location})`}
      </span>
      <span
        className='text-customRed text-16px cursor-pointer font-bold sm:w-1/4 pr-4 text-right'
        onClick={onRemove}
      >
        Delete
      </span>
    </p>
  </div>
);
export default SavedRouteCard;
