import React from 'react';
import { FiCheck, FiSlash } from 'react-icons/fi';

type Props = {
  type: 'success' | 'warning' | 'error' | 'neutral';
  value: string;
};

const Chip: React.FC<Props> = ({ type, value }) => {
  const colors = () => {
    switch (type) {
      case 'success':
        return 'text-stripe-green-dark bg-stripe-green-light';
      case 'error':
        return 'bg-red-200 text-red-900';
      case 'warning':
        return 'bg-indigo-200 text-indigo-900';
      case 'neutral':
        return 'bg-gray-300 text-gray-900';
    }
  };
  const Icon = () => {
    switch (type) {
      case 'error':
        return <FiSlash size={15} />;
      default:
        return <FiCheck size={15} />;
    }
  };
  return (
    <div
      className={`flex gap-2 px-4 py-2 rounded-md border-none font-black ${colors()}`}
    >
      {value ?? type} <Icon />
    </div>
  );
};
export default Chip;
