import React from 'react';

import { BellIcon } from '@/pages/views/BellIcon';

type BellProps = {
  unreadCount: number;
};
const Bell: React.FC<BellProps> = ({ unreadCount }) => {
  return (
    <div className='cursor-pointer flex justify-center items-center relative'>
      <BellIcon />
      {unreadCount > 0 && (
        <span className='w-3 h-3 bg-customRed rounded-full absolute top-0 left-4'></span>
      )}
      <p className='pl-4'>{unreadCount}</p>
    </div>
  );
};
export default Bell;
