import React from 'react';

const BlockCodeSend = () => {
  return (
    <div className='flex flex-col items-center w-full'>
      <p className='text-customRed text-18px pb-2 font-bold leading-6 tracking-tight'>
        Code is invalid
      </p>
      <p className='text-customGrey2 py-2 text-14px'>
        The limit of attempts to enter has been exceeded.
      </p>
      <p className='text-customGrey2 pb-2  text-14px'>
        Please try again later.
      </p>
      <button className='btn bg-customBlue font-bold rounded-lg w-full mt-8 text-14px'>
        Contact support
      </button>
    </div>
  );
};
export default BlockCodeSend;
