import React from 'react';

import Button from '@/components/Buttons/Button';
import Modal from '@/components/Modal';

const RequestToSendSmsModal = ({ handleToggleModal, isOpen }) => {
  const handleRedirectToProfile = () => {
    if (window.location.pathname === '/account/edit') {
      handleToggleModal();
    } else {
      window.location.href = '/account/edit';
      handleToggleModal();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleToggleModal}
      className='md:max-w-500px p-16 box-border relative'
    >
      <div className='flex flex-col pt-6'>
        <div className='flex gap-5 items-center'>
          <div className='flex justify-center items-center'>
            <img src='/icons/warning-full.svg' alt='Warning symbol' />
          </div>
          <p className='text-24px font-bold text-darken'>
            Phone Number Confirmation Required
          </p>
        </div>
        <p className='text-16px leading-24px py-8'>
          Please enable the toggle in your profile to receive a one-time SMS
          verification code and proceed with phone number verification.{' '}
        </p>
        <p className='text-16px leading-24px'>
          This step is essential to ensure we can communicate with you about
          your bookings.
        </p>
        <div className='flex flex-col md:flex-row md:justify-between gap-6 md:gap-12 py-8 w-full'>
          <Button className='btn-cancel' onClick={handleToggleModal}>
            Cancel
          </Button>
          <Button onClick={handleRedirectToProfile}>Go To Profile</Button>
        </div>
      </div>
    </Modal>
  );
};
export default RequestToSendSmsModal;
