export const camelize = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};
export const AIRCRAFT_TYPES_DATA = [
  { value: 'helicopter', label: 'Helicopter', color: '#171717' },
  { value: 'floatPlane', label: 'Sea-Plane', color: '#2563eb' },
  { value: 'pistonSingle', label: 'Piston Single', color: '#047857' },
  { value: 'multiEngine', label: 'Piston Twin', color: '#6EE7B7' },
  { value: 'turboProp', label: 'Turbo Prop', color: '#06b6d4' },
  { value: 'vlj', label: 'Very Light Jet', color: '#A5B4FC' },
  { value: 'lightJet', label: 'Light Jet', color: '#4F46E5' },
  { value: 'mediumJet', label: 'Mid Size Jet', color: '#e11d48' },
  { value: 'superMidSizeJet', label: 'Super Mid Size Jet', color: '#FCA5A5' },
  { value: 'heavyJet', label: 'Heavy Jet', color: '#d946ef' },
  { value: 'airliner', label: 'Airliner', color: '#f97316' },
  {
    value: 'ultraLongRangeJet',
    label: 'Ultra Long Range Jet',
    color: '#9333ea',
  },
];

export const SAFETY = [
  { label: 'ARG/US', value: 'ARG/US' },
  { label: 'Wyvern', value: 'Wyvern' },
];

// default amenities list
export const AMENITIES = [
  'Airshow moving map',
  'CD/Blu-ray Players',
  'iPod/MP3 Compatible',
  'WiFi/Internet',
  'Satellite telephone system',
  'Satellite TV',
  'Airshow',
  'Beverage Center',
  'Entertainment System',
  'Satellite Phone',
  'WiFi',
  'Apple Device/MP3 Compatible',
  'Cabin Attendant',
  'Enclosed Lavatory',
  'Espresso Machine',
  'Full Galley',
  'Game Console Compatible',
  'Fax',
  'Power Outlets',
  'Oven',
  'Microwave',
  'Oven and Microwave',
  'Flight Phone',
  'International WiFi',
  'Enclosed Rear Lavatory',
  '110 Volt Outlets',
  'LCD monitors',
  'Convection Oven',
  'On-Board SATCOM Link & Printer',
  'Chiller',
  'Fridge',
  'Full Lavatory',
  'Galley/Bar',
  'Coffee Maker',
  'Warming Oven',
  'Fully Integrated GPS',
  'DVD players',
  'Upscale amenities',
  'Meal Service',
  'Internet Access',
  'Cabin Temperature Control',
  'High Temperature Oven',
  'Aircell SAT Phone',
  'Airshow Passenger Information System',
  'Coffee Dispenser',
  'Satellite Radio',
  'Satellite Weather',
  'Domestic WiFi',
  'Leather Seats',
  'Club Seating',
  'Pet Friendly',
  'Smoking Allowed',
  'No Owner Approval Required',
  'Master Bedroom',
  'Refreshment Center',
  'Noise Cancelling Headsets',
  'Fold-out Tables',
  'Stand-up Cabin',
];

// countries
export const COUNTRIES = [
  { label: 'Argentina', value: 'AR' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Canada', value: 'CA' },
  { label: 'Chile', value: 'CL' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Egypt', value: 'EG' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Greece', value: 'GR' },
  { label: 'Hong Kong SAR China', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Japan', value: 'JP' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Malta', value: 'MT' },
  { label: 'Mexico ', value: 'MX' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Norway', value: 'NO' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Romania', value: 'RO' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Trinidad & Tobago', value: 'TT' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
];

// subscription tiers
export const GRADIENTS = [
  'from-green-400 to-blue-500',
  'from-yellow-400 to-red-500',
  'from-indigo-400 to-pink-500',
];

export type SubscriptionTier = {
  bg: string;
  name: string;
  text: string;
  price: {
    monthly: string;
    annually: string;
  };
};

export const SUBSCRIPTIONS: SubscriptionTier[] = [
  {
    bg: 'from-green-400 to-blue-500',
    name: 'first tier',
    text: 'text-white',
    price: {
      monthly: '$38',
      annually: '$400',
    },
  },
  {
    bg: 'from-yellow-400 to-red-500',
    name: 'Second tier',
    text: 'text-white',
    price: {
      monthly: '$38',
      annually: '$400',
    },
  },
  {
    bg: 'from-indigo-400 to-pink-500',
    name: 'Third tier',
    text: 'text-white',
    price: {
      monthly: '$38',
      annually: '$400',
    },
  },
  {
    bg: 'from-yellow-200 to-yellow-600',
    name: 'Third Four',
    text: 'text-white',
    price: {
      monthly: '$38',
      annually: '$400',
    },
  },
];

export type SubscriptionType =
  | 'free'
  | 'basic'
  | 'delux'
  | 'astrolux'
  | 'ultralux';
export const backgrounds: Record<SubscriptionType, string> = {
  free: 'bg-free',
  basic: 'bg-basic',
  delux: 'bg-delux',
  astrolux: 'bg-astrolux',
  ultralux: 'bg-ultralux',
};
