import { useMemo } from 'react';

import { useDuplicateEmptyLeg, useEditEmptyleg } from '@/API/aircraftApi';
import { Aircraft, Emptyleg } from '@/API/models';
import { formatDate, formatTime } from '@/Utils/helpers';
import { yup } from '@/Utils/validationConfig';

export const useEditEmptyLegForm = (
  aircraft: Aircraft,
  emptyleg: Emptyleg,
  afterSubmit: () => void,
) => {
  const editEmptyleg = useEditEmptyleg();
  const editFlyeasyEmptyleg = useDuplicateEmptyLeg();

  const onSubmit = async (values: typeof initialValues) => {
    console.log(values);

    const updated = {
      departure_airport: values.departure_airport,
      arrival_airport: values.arrival_airport,
      date1: `${formatDate(values.date_range.from, 'mm/dd/yyyy')}, ${
        values.date_range.from_time ?? '12:00 AM'
      }`,
      date2: `${formatDate(values.date_range.to, 'mm/dd/yyyy')}, ${
        values.date_range.to_time ?? '12:00 AM'
      }`,
      flightTimeDec: values.flight_time,
      price: values.price,
    };

    if (emptyleg.evolux) {
      await editEmptyleg.mutateAsync({
        aircraft_id: `${aircraft.id}`,
        id: emptyleg._id ?? emptyleg.emptyleg_id,
        emptyLeg: updated,
      });
    } else {
      await editFlyeasyEmptyleg.mutateAsync({
        aircraft_id: `${aircraft.id}`,
        duplicateEmptyleg: updated,
        emptyLeg: emptyleg,
      });
    }
    afterSubmit();
  };

  const initialValues = useMemo(() => {
    const from = new Date(emptyleg.date1);
    const to = new Date(emptyleg.date2);

    return {
      date_range: {
        from,
        from_time: formatTime(from),
        to,
        to_time: formatTime(to),
      },
      flight_time: emptyleg.flightTimeDec,
      departure_airport: emptyleg.departureAirport,
      arrival_airport: emptyleg.arrivalAirport,
      price: emptyleg.price,
    };
  }, [emptyleg]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        date_range: yup.object({
          from: yup.date().required(),
          to: yup.date().required(),
        }),
        flight_time: yup.number().optional(),
        departure_airport: yup.object({
          airport_code: yup.string().required(),
        }),
        arrival_airport: yup.object({
          airport_code: yup.string().required(),
        }),
        price: yup.number().positive().optional(),
      }),
    [],
  );

  return { initialValues, validationSchema, onSubmit };
};
