import { useMutation, useQuery } from 'react-query';

import api from '@/API/config';
import { QueryMethod } from '@/types/API';
import { StipeAccountFormData } from '@/Utils/formHooks/useStripeAccountForm';
import { StripeBankInfoFormData } from '@/Utils/formHooks/useStripeBankInfoForm';

export const useCreateStripeAccount = () => {
  return useMutation(async (account: StipeAccountFormData) => {
    const { data } = await api.post<StripeCreateAccountResponse>(
      '/operator/stripe_account/create_account',
      account,
    );
    return data;
  });
};
export const useEditStripeAccount = () => {
  return useMutation(async (formData: StripeBankInfoFormData) => {
    const { data } = await api.post('/operator/stripe_account', formData);
    return data;
  });
};

export const useGetClientSecret: QueryMethod<StripePaymentIntent, string> = (
  url,
  options?,
) => {
  return useQuery(
    'stripe_client_secret',
    async () => {
      const { data } = await api.get<StripePaymentIntent>(url);
      return data;
    },
    { staleTime: Infinity, ...options },
  );
};
type StripePaymentIntent = {
  client_secret: string;
  success: boolean;
};
export interface StripeCreateAccountResponse {
  account: StripeAccountType;
  success: boolean;
  error?: string;
}

export interface StripeAccountType {
  id: string;
  object: string;
  business_profile: BusinessProfile;
  capabilities: Capabilities;
  charges_enabled: boolean;
  country: string;
  created: number;
  default_currency: string;
  details_submitted: boolean;
  email: string;
  external_accounts: ExternalAccounts;
  future_requirements: Requirements;
  metadata: Metadata;
  payouts_enabled: boolean;
  requirements: Requirements;
  settings: Settings;
  tos_acceptance: StripeAccountTypeTosAcceptance;
  type: string;
}

export interface BusinessProfile {
  mcc: string;
  name: string;
  product_description: string;
  support_address: SupportAddress;
  support_email: string;
  support_phone: string;
  support_url: string;
  url: string;
}

export interface SupportAddress {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}

export interface Capabilities {
  card_payments: string;
  transfers: string;
}

export interface ExternalAccounts {
  object: string;
  data: ExternalAccountData[];
  has_more: boolean;
  url: string;
}

export interface ExternalAccountData {
  id: string;
  object: string;
  account: string;
  account_holder_name: string;
  account_holder_type: string | null;
  account_type: string | null;
  available_payout_methods: string[];
  bank_name: string;
  country: string;
  currency: string;
  default_for_currency: boolean;
  fingerprint: string;
  last4: string;
  metadata: Metadata;
  routing_number: string;
  status: string;
}

export interface Metadata {}

export interface Requirements {
  alternatives: any[];
  current_deadline: string | null;
  currently_due: any[];
  disabled_reason: string | null;
  errors: any[];
  eventually_due: any[];
  past_due: any[];
  pending_verification: any[];
}

export interface Settings {
  bacs_debit_payments: Metadata;
  branding: Branding;
  card_issuing: CardIssuing;
  card_payments: CardPayments;
  dashboard: Dashboard;
  payments: Payments;
  payouts: Payouts;
  sepa_debit_payments: Metadata;
}

export interface Branding {
  icon: string;
  logo: string | null;
  primary_color: string;
  secondary_color: string | null;
}

export interface CardIssuing {
  tos_acceptance: CardIssuingTosAcceptance;
}

export interface CardIssuingTosAcceptance {
  date: string | null;
  ip: string | null;
}

export interface CardPayments {
  decline_on: DeclineOn;
  statement_descriptor_prefix: string;
}

export interface DeclineOn {
  avs_failure: boolean;
  cvc_failure: boolean;
}

export interface Dashboard {
  display_name: string;
  timezone: string;
}

export interface Payments {
  statement_descriptor: string;
  statement_descriptor_kana: string;
  statement_descriptor_kanji: string;
}

export interface Payouts {
  debit_negative_balances: boolean;
  schedule: Schedule;
  statement_descriptor: string | null;
}

export interface Schedule {
  delay_days: number;
  interval: string;
}

export interface StripeAccountTypeTosAcceptance {
  date: number;
  ip: string;
  user_agent: string | null;
}
