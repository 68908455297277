import { GoogleMap, InfoWindow, useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { Emptyleg } from '@/API/models';
import DisplayInfo from '@/components/DisplayInfo';
import MapLine from '@/components/MapLine';
import { AIRCRAFT_TYPES_DATA } from '@/Utils/constants';

type Props = {
  apiKey: string;
  routes?: Emptyleg[];
  selectedRoute?: number;
} & (
  | { goTo: (index: number) => void; simple?: never }
  | { simple?: true; goTo?: never }
);
// necessary attributes for routes
// TODO
// {
//   ac: { type: 'heavyJet' },
//   airportFrom: { lat: 35.00020471167232, lng: -118.41562946610757 },
//   airportTo: { lat: 35.00020471167232, lng: -79.41562946610757 },
// }

const defaultProps = {
  center: {
    lat: 39.783676232579275,
    lng: -101.92174054935084,
  },
  zoom: 4,
};
const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

const SearchMap: React.FC<Props> = ({
  routes = [],
  goTo,
  apiKey,
  selectedRoute,
  simple = false,
}) => {
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: GOOGLE_MAPS_KEY,
    googleMapsApiKey: apiKey,
  });

  const [visible, setVisible] = useState(false);
  const [selectedEmptyLeg, setSelectedEmptyLeg] = useState(0);
  const [windowPos, setWindowPos] = useState<google.maps.LatLng | null>(null);
  const mapRef = useRef<google.maps.Map>();
  const [isLegendOpen, setIsLegendOpen] = useState(true);

  const centerMap = () => {
    if (!mapRef.current) return;
    // center the map
    const bounds = new google.maps.LatLngBounds();
    routes.forEach((r) => {
      bounds.extend({
        lat: r.departureAirport.lat,
        lng: r.departureAirport.lng,
      });
      bounds.extend({
        lat: r.arrivalAirport.lat,
        lng: r.arrivalAirport.lng,
      });
    });
    // bounds.extend(Item_1);
    mapRef.current.fitBounds(bounds);
  };

  const showRoute = (index: number) => {
    setSelectedEmptyLeg(index);

    const arrivalIsFocused =
      windowPos?.lat() == routes[index].arrivalAirport.lat &&
      windowPos?.lng() == routes[index].arrivalAirport.lng;
    const newPosition = arrivalIsFocused
      ? new google.maps.LatLng(
          routes[index].departureAirport.lat,
          routes[index].departureAirport.lng,
        )
      : new google.maps.LatLng(
          routes[index].arrivalAirport.lat,
          routes[index].arrivalAirport.lng,
        );
    setWindowPos(newPosition);
    mapRef.current?.setZoom(10);
    mapRef.current?.setCenter(newPosition);

    setVisible(true);
  };
  useEffect(() => {
    if (selectedRoute !== undefined) {
      showRoute(selectedRoute);
    }
  }, [selectedRoute]);
  useEffect(() => {
    if (!simple) {
      setVisible(false);
      setSelectedEmptyLeg(0);
    }
    if (!routes.length || !isLoaded || !mapRef.current) return;
    centerMap();
  }, [routes, isLoaded, mapRef.current]);

  if (!isLoaded) return null;
  return (
    <div
      id='search_map'
      className={`map lg:sticky  order-1 lg:order-2 ${
        routes.length === 0 && 'hidden lg:block'
      }`}
    >
      <GoogleMap
        options={{
          scaleControl: false,
          zoomControl: false,
          panControl: false,
          mapTypeControl: false,
        }}
        mapContainerClassName='relative'
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        mapContainerStyle={containerStyle}
        onLoad={(map) => {
          mapRef.current = map;
          if (routes.length) {
            centerMap();
          }
        }}
      >
        <>
          <div
            className={`legend cursor-pointer p-4 absolute bottom-2 left-2 bg-white ${
              isLegendOpen ? 'legend-expanded' : ''
            }`}
            onClick={() => setIsLegendOpen((v) => !v)}
          >
            Legend
            {isLegendOpen ? (
              <FaCaretDown className='float-right' />
            ) : (
              <FaCaretUp className='float-right' />
            )}
            <div className='legend-content mt-4'>
              {AIRCRAFT_TYPES_DATA.map((ac) => (
                <p
                  key={ac.value}
                  className='p-1 mt-2 rounded text-white'
                  style={{ backgroundColor: ac.color }}
                >
                  {ac.label}
                </p>
              ))}
            </div>
          </div>
          {goTo && visible && windowPos && (
            <InfoWindow
              position={windowPos}
              onCloseClick={() => setVisible(false)}
              onUnmount={() => setVisible(false)}
            >
              <>
                {!!routes[selectedEmptyLeg] && (
                  <DisplayInfo
                    {...routes[selectedEmptyLeg]}
                    routes={routes}
                    onSelect={(idx) => goTo(idx ?? selectedEmptyLeg)}
                  />
                )}
              </>
            </InfoWindow>
          )}
          {routes.length &&
            routes.map((r, i) => {
              try {
                const highlighted =
                  r.departureAirport.airport_code ==
                    routes[selectedEmptyLeg].departureAirport.airport_code &&
                  r.arrivalAirport.airport_code ==
                    routes[selectedEmptyLeg].arrivalAirport.airport_code;
                return (
                  <MapLine
                    key={r._id ?? i}
                    highlighted={highlighted}
                    emptyleg={{
                      ac: { type: r.ac?.type ?? '', typeJson: r.ac.typeJson },
                      departureAirport: r.departureAirport,
                      arrivalAirport: r.arrivalAirport,
                    }}
                    onHover={({ latLng }) => {
                      if (simple) return;
                      setSelectedEmptyLeg(i);
                      setWindowPos(latLng);
                      setVisible(true);
                    }}
                  />
                );
              } catch (error) {
                return null;
              }
            })}
        </>
      </GoogleMap>
    </div>
  );
};
export default SearchMap;
