import axios from 'axios';

const api = axios.create();
api.interceptors.request.use((request) => {
  // getting token from dom
  const token = document.querySelector<any>('meta[name="csrf-token"]').content;
  const headers = {
    'X-CSRF-Token': token,
    'Content-Type': 'application/json',
  };

  request.headers = { ...request.headers, ...headers };

  // operator_id param when admin act as operator
  const searchParams = new URLSearchParams(window.location.search);
  const operator_id = searchParams.get('operator_id');
  if (operator_id !== null) {
    request.params = { ...request.params, operator_id };
  }

  return request;
});

export default api;
