import React, { FC, useEffect, useMemo,useState } from 'react';

import { convertSecondsToTimeFormat } from '@/Utils/helpers';
import { useCSRFToken } from '@/Utils/useCSRFToken';

type TimerProps = {
  smsSentAt: string;
  phone: string;
};

const SMS_COOLDOWN = 60;

const Timer: FC<TimerProps> = ({ smsSentAt, phone }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);

  const cooldown = useMemo(() => {
    if (!smsSentAt) return 0;

    const smsSentDate = new Date(smsSentAt);
    const timeElapsed = Math.floor((+new Date() - +smsSentDate) / 1000);
    const timeRemaining = SMS_COOLDOWN - timeElapsed;
    return Math.max(0, timeRemaining);
  }, [smsSentAt]);

  const csrfToken = useCSRFToken();

  const handleSendAgainClick = () => {
    const form = document.getElementById('phoneForm') as HTMLFormElement;

    if (form) {
      form.submit();
    }
  };

  useEffect(() => {
    const endTime = Date.now() + cooldown * 1000;

    const updateTimer = () => {
      const now = Date.now();
      const remainingTime = endTime - now;

      if (remainingTime <= 0) {
        setTimeRemaining(0);
        return;
      }

      setTimeRemaining(Math.floor(remainingTime / 1000));
    };

    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [cooldown]);

  return (
    <>
      {timeRemaining > 0 ? (
        <>
          <div className='flex flex-col items-center w-full'>
            <p className='text-customGrey2 text-center py-8 text-14px'>
              Sending a new code will be available in:
            </p>
          </div>
          <p className='text-customRed font-bold leading-5 text-center'>
            {convertSecondsToTimeFormat(timeRemaining)}
          </p>
        </>
      ) : (
        <>
          <p className='text-customGrey2 text-center pt-6  text-14px'>
            Haven’t received SMS?
          </p>
          <button
            onClick={handleSendAgainClick}
            className='text-customBlue2 text-center cursor-pointer text-14px text-customBlue pt-3 border-none bg-transparent'
          >
            Tap to send again
          </button>

          <form id='phoneForm' action='/users/send_phone_code' method='POST'>
            <input type='hidden' name='authenticity_token' value={csrfToken} />
            <input type='hidden' name='phone' value={phone} />
          </form>
        </>
      )}
    </>
  );
};

export default Timer;
