import { Form, Formik } from 'formik';
import React from 'react';

import { Aircraft, Emptyleg } from '@/API/models';
import { SubmitBtn } from '@/components/Forms';
import EmptyLegFields from '@/components/Operator/Shared/EmptyLegFields';
import { useEditEmptyLegForm } from '@/Utils/formHooks/useEditEmptyLegForm';

type Props = {
  aircraft: Aircraft;
  emptyleg: Emptyleg;
  afterSubmit: () => void;
};

const EditEmptyLegForm: React.FC<Props> = ({
  aircraft,
  afterSubmit,
  emptyleg,
}) => {
  const form = useEditEmptyLegForm(aircraft, emptyleg, afterSubmit);
  return (
    <Formik {...form} validateOnChange enableReinitialize>
      {() => (
        <Form>
          <h1 className='text-4xl pb-8 px-4'>
            Edit EmptyLeg - {aircraft?.tail_number}
          </h1>
          <EmptyLegFields />
          <SubmitBtn text='Submit Changes' />
        </Form>
      )}
    </Formik>
  );
};
export default EditEmptyLegForm;
