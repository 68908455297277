import React from 'react';
const Twitter = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.28156 0.123901H0.781738L1.65349 1.34436L7.81645 9.9725L1.44038 17.8762H4.24099L9.13165 11.8138L13.2311 17.5529L13.4619 17.8762H13.8591H17.7183H19.2181L18.3464 16.6557L6.76882 0.447117L6.53795 0.123901H6.14075H2.28156ZM14.2563 16.3325L3.78139 1.66758H5.74355L16.2185 16.3325H14.2563ZM10.8702 6.18712L12.1854 8.0284L18.5621 0.123901H15.7615L10.8702 6.18712Z'
        fill='white'
      />
    </svg>
  );
};
export default Twitter;
