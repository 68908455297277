import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

import useSearchAirport, { Airport } from '@/API/airportApi';
import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';
import useDebounce from '@/Utils/useDebounce';

type Props = InputProps & { cityField?: string };

export const useAirportInputData = (props: Props) => {
  const [{ value }, { touched }, { setValue }] = useField<Airport | null>(
    props,
  );
  const { setFieldValue, setFieldTouched, submitCount } = useFormikContext();

  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearch = useDebounce(searchValue);
  const [selected, setSelected] = useState<Airport | null>();

  const { isLoading, data } = useSearchAirport(debouncedSearch, {
    enabled: debouncedSearch?.trim().length > 0,
    retryOnMount: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    submitCount > 0 && setFieldTouched(`${props.name}.airport_code`, true);
  }, [submitCount]);

  const onBlur = () => {
    setFieldTouched(`${props.name}.airport_code`, true);
  };

  useEffect(() => {
    // convert initial value (only code) to an airport (selected) object using a search
    if (!searchValue && value?.airport_code && !value?.label) {
      setSearchValue(value.airport_code);
    }
    // convert initial value if its a full airport
    if (!searchValue && value?.airport_code && value?.label) {
      setSelected(value);
    }
  }, [value, searchValue]);

  useEffect(() => {
    if (!value || !value.airport_code) return setSelected(null);
    if (!data) return;

    setSelected(
      data?.find((airport) => airport.airport_code == value.airport_code),
    );
  }, [value, data]);

  return {
    setValue,
    setFieldValue,
    isLoading,
    data,
    setSearchValue,
    selected,
    onBlur,
  };
};

const AirportInput: React.FC<Props> = ({ cityField, ...props }) => {
  const {
    selected,
    data,
    isLoading,
    setSearchValue,
    setFieldValue,
    setValue,
    onBlur,
  } = useAirportInputData(props);

  return (
    <FormElement {...props}>
      <ReactSelect
        isClearable
        id={props.name}
        inputId={props.name}
        onBlur={onBlur}
        value={selected}
        options={data}
        isLoading={isLoading}
        placeholder='City, airport code…'
        onInputChange={(search, _meta) => {
          setSearchValue(search);
        }}
        onChange={(airport, meta) => {
          cityField && setFieldValue(cityField, airport?.location);
          if (meta.action === 'clear') {
            return setValue({} as Airport);
          }
          return setValue(airport);
        }}
        styles={{
          control: (styles) => ({
            ...styles,
            borderRadius: '4px',
            fontSize: '16px',
            height: '40px',
            minHeight: '40px',
            display: 'flex',
            alignItems: 'center',
          }),
          valueContainer: (styles) => ({
            ...styles,
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
          }),
          input: (styles) => ({
            ...styles,
            margin: 0,
            padding: 0,
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            height: '40px',
          }),
          menu: (provided) => ({
            ...provided,
            fontSize: '16px',
          }),
        }}
        components={{ DropdownIndicator: null }}
        name={props.name}
      />
      <ErrorMessage name={`${props.name}.airport_code`} />
    </FormElement>
  );
};
export const AirportInputContent: React.FC<Props> = (props) => {
  const { selected, data, isLoading, setSearchValue, setFieldValue, setValue } =
    useAirportInputData(props);

  return (
    <ReactSelect
      autoFocus
      onBlur={props.onBlur}
      value={selected}
      options={data}
      isLoading={isLoading}
      placeholder='City, airport code…'
      onInputChange={(search, _meta) => {
        setSearchValue(search);
      }}
      onChange={(airport) => {
        setValue(airport);
        const cityFiled = props.name.includes('dep')
          ? 'departure_airport_city'
          : 'arrival_airport_city';
        setFieldValue(cityFiled, airport?.location);
      }}
      styles={{
        control: (styles) => ({
          ...styles,
          borderRadius: '4px',
          fontSize: '16px',
          height: '40px',
          minHeight: '40px',
          display: 'flex',
          alignItems: 'center',
        }),
        valueContainer: (styles) => ({
          ...styles,
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 8px',
        }),
        input: (styles) => ({
          ...styles,
          margin: 0,
          padding: 0,
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          height: '40px',
        }),
      }}
      components={{ DropdownIndicator: null }}
      name={props.name}
      inputId={props.name}
      id={props.name}
    />
  );
};
export default React.memo(AirportInput);
