import { useMemo, useState } from 'react';

import { Route, useRegisterAccount } from '@/API/userApi';
import { formatDate, strDate } from '@/Utils/helpers';
import { yup } from '@/Utils/validationConfig';

export const useRegisterForm = (afterRegister: () => void) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState<string[]>();

  const handleTabClick = (tab: number) => {
    if (tab > currentStep) return;
    setCurrentStep(tab);
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        'g-recaptcha-response':
          currentStep > 0 ? yup.string().required() : yup.string().optional(),
        email: yup.string().email().required(),
        date_of_birth: yup
          .string()
          .required()
          .test({
            message: 'the format must be mm/dd/yyyy',
            test: (value) => {
              if (!value) return false;
              return formatDate(strDate(value)) == value;
            },
          }),
        username: yup.string().min(2).required(),
        first_name: yup.string().min(2).required(),
        last_name: yup.string().min(2).required(),
        phone: yup.string().min(6).required(),
        password: yup.string().min(8).required(),
        password_confirmation: yup
          .string()
          .required()
          .oneOf([yup.ref('password')], 'Passwords must match'),
        agree_on_terms: yup
          .boolean()
          .isTrue(
            'Please review and agree to the terms of service to continue.',
          )
          .required(),
        agree_on_sms_receive: yup
          .boolean()
          .isTrue('Please review and agree to receive text messages.'),
      }),
    [currentStep],
  );
  const initialValues = useMemo(() => {
    return {
      savedRoutesForm: {
        departure_airport: {},
        arrival_airport: {},
        returned: false,
        radius: 400,
      } as Route,
      // user Data
      receive_shared_flight_notification: true,
      receive_emptyleg_flight_notification: true,
      newsletter_frequency: 0,
      saved_routes: [],
      password: '',
      password_confirmation: '',
      email: '',
      username: '',
      first_name: '',
      last_name: '',
      street: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
      time_zone: '',
      height: '',
      weight: '',
      phone: '',
      agree_on_terms: false,
      agree_on_sms_receive: false,
      'g-recaptcha-response': '',
      date_of_birth: '',
    };
  }, []);
  // initially touched to fix validation check
  const touched = {
    savedRoutesForm: {
      arrival_airport: { airport_code: true },
      departure_airport: { airport_code: true },
      radius: true,
    },
  };

  const registerAccount = useRegisterAccount();

  const onSubmit = async (values) => {
    if (currentStep === 0) {
      return setCurrentStep(1);
    }
    const data = { user: values };
    delete data.user.savedRoutesForm;
    try {
      const response = await registerAccount.mutateAsync(data);
      if (response.success) {
        return afterRegister();
        // return window.location.assign(response.signin_redirection);
      }
      setErrors(response.errors ?? ['error during signup ']);
    } catch (err) {
      console.log({ error: err });
    }
    // show success
  };
  return {
    initialValues,
    validationSchema,
    touched,
    onSubmit,
    currentStep,
    handleTabClick,
    errors,
    setErrors,
  };
};
