import React from 'react';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';

type CheckboxBtnProps = {
  onClick: () => void;
  isActive: boolean;
};

const ACTIVE = 'bg-gray-100  text-green-500';
const INACTIVE = 'bg-gray-200 hover:bg-gray-400 text-gray-900';
const CheckboxBtn: React.FC<CheckboxBtnProps> = ({
  onClick,
  children,
  isActive,
}) => {
  return (
    <button
      className={`flex w-full cursor-pointer my-5  px-5 py-5 border-none text-xl font-black rounded-md ${
        isActive ? ACTIVE : INACTIVE
      }`}
      onClick={onClick}
    >
      {isActive ? (
        <AiFillCheckCircle size={20} className='mx-5' />
      ) : (
        <AiOutlineCheckCircle size={20} className='mx-5' />
      )}{' '}
      {children}
    </button>
  );
};
export default CheckboxBtn;
