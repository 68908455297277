import 'react-phone-input-2/lib/style.css';

import { useField } from 'formik';
import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';

type Props = InputProps;

const PhoneInput: React.FC<Props> = (props) => {
  const [formik, { error }, { setValue }] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormElement {...props}>
      <div className='flex items-baseline'>
        {props.preText && (
          <p className='pr-1 inline-block text-gray-700 font-bold'>
            {props.preText}
          </p>
        )}
        <ReactPhoneInput
          country={'us'}
          placeholder={props.placeholder || '....'}
          disabled={props.disabled}
          {...formik}
          onChange={(value) => {
            setValue(value.startsWith('+') ? value : `+${value}`);
          }}
          inputClass={`w-full rounded-lg text-16px h-auto h-40px border-0 `}
          containerStyle={{ borderRadius: '4px' }}
          containerClass={`w-full rounded-lg ${error ? 'border-customRed border-solid border-1' : isFocused ? 'border-customBlue border-solid border-1' : 'border-inputBorder border-solid border-1'} `}
          inputProps={{ name: formik.name }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {props.children}
      </div>
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
export default PhoneInput;
